<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Principal's Desk</h4>
                    <div class="card-body ">
                        <div class="text-center">
                            <img src="/assets/img/message/principal.jpg" alt="" class="img-fluid rounded-4">
                        </div>
                        <p class="inner-lead">To the Esteemed Hindu Sr. Sec. School Community,</p>
                        <p class="inner-lead">Ours is a distinguished institution with a rich legacy of academic excellence, cultural heritage and holistic development. I  am deeply honored to head this vibrant community and  am committed to building upon the strong foundation that has been laid over the years.</p>
                        <p class="inner-lead">My vision for Hindu Sr. Sec. School is to create a dynamic and inclusive learning environment where students can unlock their unique potential and thrive. I firmly believe in fostering a culture of critical thinking, creativity and innovation to prepare our students to excel in a rapidly changing world. Academic rigor is at the core of our efforts, with a focus on implementing innovative teaching methodologies, strengthening curricular offerings and providing
                            personalized learning support to help students achieve their academic aspirations.
                            </p>
                        <p class="inner-lead">Equally, I aim to nurture holistic development by promoting a vibrant co-curricular program that encourages active participation in sports, arts and community service. These opportunities will help our students grow into well-rounded individuals with strong values and a sense of
                            responsibility.
                            </p>
                        <p class="inner-lead">I also believe in the power of collaboration and work closely with parents, alumni and the wider community to create a strong support system for our students.Prioritizing thewell-being of every member of our school community, I am committed to fostering a safe, inclusive and nurturing environment where students feel valued, respected and empowered to succeed.</p>
                        <p class="inner-lead">Together, with the dedication of our faculty, staff and parents. I aim to lead Hindu Sr Sec School into a future filled with new opportunities and continued success for our students.</p>
                        <p class="inner-lead">Sincerely,</p>
                        <p class="inner-lead fw-bold">Ms. Hema Chaturvedi</p>
                        <p class="inner-lead fw-bold">Principal</p>
                        <p class="inner-lead fw-bold">Hindu Sr. Sec. School</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>