<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">LIST  OF STUDENTS SELECTED FOR STATE LEVEL COMPETITION GAMES (2023-24)</h4>
                    <div class="card-body ">

                        <div class="overflow-x-scroll">
                            <table class="table table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <th>Class</th>
                                        <th>Game</th>
                                        <th>District Position </th>
                                        <th>State Position </th>
                                    </tr>
                                    <tr *ngFor="let student of players">
                                        <td>{{ student.name }}</td>
                                        <td>{{ student.class }}</td>
                                        <td>{{ student.game }}</td>
                                        <td>{{ student.districtPosition }}</td>
                                        <td>{{ student.statePosition }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>