<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Disciplinary Measures</h4>
                    <div class="card-body ">
                        <ul>
                            <li>
                                <p class="inner-lead">Discipline is a must for establishing an institutional climate
                                    that promotes the intellectual, cultural and personality development of the young
                                    ones.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Parents and guardians have to co-operate with the authorities of
                                    the school for enforcing discipline and regularity in the school.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Every student is expected to be of good conduct and behaviour.
                                    Disobedience or unbecoming conduct which may negatively influence the moral tone of
                                    the school is enough reason for the dismissal of any student from the school.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Dismissal is immanent to any student who is persistently
                                    insubordinate or is repeatedly and willfully mischievous.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Dismissal is immanent to anyone who is guilty of malpractice in
                                    connection with examinations.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Who has committed an act of serious indiscipline and misbehaviour.
                                </p>
                            </li>
                            <li>
                                <p class="inner-lead">Who in the opinion of the Head of the school has an unwholesome
                                    influence on his fellow pupils.</p>
                            </li>
                            <li>
                                <p class="inner-lead">They may be expelled permanently or removed from the school for a
                                    specified period of time.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Two official written warnings will be given for less serious
                                    cases, before the final expulsion. Irregular attendance, habitual idleness and
                                    neglect of homework by the students may lead to serious remedial measures. In other
                                    cases of serious lapses, corrective action will be given according to the gravity of
                                    the matter.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Disobedient student will be served with light disciplinary
                                    proceedings in view of making him/her restricted. Parents shall understand and
                                    cooperate with the school authorities keeping the spirit and mind of the
                                    disciplinary actions administered on misbehaving students.; nor shall they indulge
                                    in any form of rivalry among themselves on account of certain unwarranted behavior
                                    the students in the school.</p>
                            </li>
                            <li>
                                <p class="inner-lead">If the parents are too sensitive with regard to corrective
                                    measures, they shall inform the Principal in writing.</p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>