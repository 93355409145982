<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Sa Re Ga Ma</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Music is an integral part of our life. It is the most relaxing diversion
                            in our stressful life. Children of all ages love music classes. This activity starts right
                            from the beginning, the Nursery class and continues up to the senior secondary school
                            students. Hindu Sr. Sec School trains children in both – vocal and instrumental music. Songs
                            are also earmarked for special occasions like Independence Day, Republic Day, national and
                            social festivals. Students are trained in instruments according to their talent and ability.
                            The school has instruments like harmonium, tabla, keyboard, guitar and various drums. An
                            orchestra/band is formed of students who play various instruments and they are taught both
                            Indian and western musical piece. The school has talented teachers who teach vocal and
                            instrumental music. They also conduct individual/group singing competitions among students.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>