import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interhouse',
  templateUrl: './interhouse.component.html',
  styleUrls: ['./interhouse.component.scss']
})
export class InterhouseComponent implements OnInit {
  studentsActivities = [
    { srNo: 1, name: 'Neha', classSec: 'XII-B', activity: 'Breaking News Competition', house: 'Azad', position: '1st' },
    { srNo: 2, name: 'Mansi', classSec: 'XI-C', activity: 'Breaking News Competition', house: 'Azad', position: '1st' },
    { srNo: 3, name: 'Khushi', classSec: 'X-D', activity: 'Breaking News Competition', house: 'Azad', position: '1st' },
    { srNo: 4, name: 'Arpit Pandey', classSec: 'XII-B', activity: 'Breaking News Competition', house: 'Tilak', position: '2nd' },
    { srNo: 5, name: 'Sneha', classSec: 'XI-D', activity: 'Breaking News Competition', house: 'Tilak', position: '2nd' },
    { srNo: 6, name: 'Vanshika', classSec: 'IX-B', activity: 'Breaking News Competition', house: 'Tilak', position: '2nd' },
    { srNo: 7, name: 'Lakshit', classSec: 'III-Topaz', activity: 'G.K Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 8, name: 'Aaditya Jain', classSec: 'IV-Opal', activity: 'G.K Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 9, name: 'Rachit', classSec: 'V-Ruby', activity: 'G.K Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 10, name: 'Manvi', classSec: 'III-Ruby', activity: 'G.K Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 11, name: 'Dev', classSec: 'IV-Opal', activity: 'G.K Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 12, name: 'Devansh', classSec: 'V-Pearl', activity: 'G.K Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 13, name: 'Chesta', classSec: 'I-Pearl', activity: 'Self Introduction Competition', house: 'Subhash', position: '1st' },
    { srNo: 14, name: 'Nancy', classSec: 'II-Opal', activity: 'Self Introduction Competition', house: 'Subhash', position: '1st' },
    { srNo: 15, name: 'Sivansh', classSec: 'I-Opal', activity: 'Self Introduction Competition', house: 'Azad', position: '2nd' },
    { srNo: 16, name: 'Dharya Ridhlan', classSec: 'II-Pearl', activity: 'Self Introduction Competition', house: 'Azad', position: '2nd' },
    { srNo: 17, name: 'Ishika', classSec: 'I-Opal', activity: 'Fancy Dress Competition', house: 'Tilak', position: '1st' },
    { srNo: 18, name: 'Poorvi', classSec: 'II-Topaz', activity: 'Fancy Dress Competition', house: 'Parel', position: '2nd' },
    { srNo: 19, name: 'Lavanya', classSec: 'II-Pearl', activity: 'Fancy Dress Competition', house: 'Subhash', position: '3rd' },
    { srNo: 20, name: 'Anisha Sharma', classSec: 'IV-Topaz', activity: 'Fancy Dress Competition', house: 'Patel', position: '1st' },
    { srNo: 21, name: 'Tamanna', classSec: 'V-Opal', activity: 'Fancy Dress Competition', house: 'Azad', position: '2nd' },
    { srNo: 22, name: 'Saksham Malik', classSec: 'IV-Topaz', activity: 'Fancy Dress Competition', house: 'Azad', position: '2nd' },
    { srNo: 23, name: 'Rachel', classSec: 'V-Opal', activity: 'Fancy Dress Competition', house: 'Subhash', position: '3rd' },
    { srNo: 24, name: 'Akshit', classSec: 'VI-D', activity: 'Spellwell Competition', house: 'Azad', position: '1st' },
    { srNo: 25, name: 'Nitesh', classSec: 'VII-D', activity: 'Spellwell Competition', house: 'Azad', position: '1st' },
    { srNo: 26, name: 'Bhavishya', classSec: 'VIII-C', activity: 'Spellwell Competition', house: 'Azad', position: '1st' },
    { srNo: 27, name: 'Sonal', classSec: 'VI-C', activity: 'Spellwell Competition', house: 'Subhash', position: '1st' },
    { srNo: 28, name: 'Lakshay', classSec: 'VII-D', activity: 'Spellwell Competition', house: 'Subhash', position: '1st' },
    { srNo: 29, name: 'Janvi', classSec: 'VIII-B', activity: 'Spellwell Competition', house: 'Subhash', position: '1st' },
    { srNo: 30, name: 'Ramanuj', classSec: 'VI-D', activity: 'Spellwell Competition', house: 'Patel', position: '2nd' },
    { srNo: 31, name: 'Devank', classSec: 'VIII-B', activity: 'Spellwell Competition', house: 'Patel', position: '2nd' },
    { srNo: 32, name: 'Shivani', classSec: 'VIII-B', activity: 'Spellwell Competition', house: 'Patel', position: '2nd' },
    { srNo: 33, name: 'Devika', classSec: 'I-Topaz', activity: 'Solo Dance Competition', house: 'Azad', position: '1st' },
    { srNo: 34, name: 'Lavanya', classSec: 'I-Topaz', activity: 'Solo Dance Competition', house: 'Subhash', position: '2nd' },
    { srNo: 35, name: 'Pari', classSec: 'II-Topaz', activity: 'Solo Dance Competition', house: 'Patel', position: '3rd' },
    { srNo: 36, name: 'Visha', classSec: 'V-Topaz', activity: 'Solo Dance Competition', house: 'Tilak', position: '1st' },
    { srNo: 37, name: 'Hargun', classSec: 'III-Ruby', activity: 'Solo Dance Competition', house: 'Azad', position: '2nd' },
    { srNo: 38, name: 'Bani', classSec: 'V-Pearl', activity: 'Solo Dance Competition', house: 'Patel', position: '3rd' },
    { srNo: 39, name: 'Himani', classSec: 'IV-Opal', activity: 'Solo Dance Competition', house: 'Tilak', position: '3rd' },
    { srNo: 40, name: 'Shreya', classSec: 'VI-C', activity: 'Doha Competition', house: 'Azad', position: '1st' },
    { srNo: 41, name: 'Vansh', classSec: 'VII-C', activity: 'Doha Competition', house: 'Azad', position: '1st' },
    { srNo: 42, name: 'Bhavishya', classSec: 'VIII-C', activity: 'Doha Competition', house: 'Azad', position: '1st' },
    { srNo: 43, name: 'Anushka', classSec: 'VI-C', activity: 'Doha Competition', house: 'Patel', position: '2nd' },
    { srNo: 44, name: 'Pari', classSec: 'VII-D', activity: 'Doha Competition', house: 'Patel', position: '2nd' },
    { srNo: 45, name: 'Vanshika', classSec: 'VIII-A', activity: 'Doha Competition', house: 'Patel', position: '2nd' },
    { srNo: 46, name: 'Somya', classSec: 'II-Ruby', activity: 'Doha Recitation Competition', house: 'Patel', position: '1st' },
    { srNo: 47, name: 'Sejal', classSec: 'II-Topaz', activity: 'Doha Recitation Competition', house: 'Azad', position: '2nd' },
    { srNo: 48, name: 'Aarohi', classSec: 'II-Topaz', activity: 'Doha Recitation Competition', house: 'Tilak', position: '3rd' },
    { srNo: 49, name: 'Ritik', classSec: 'VIII-D', activity: 'Science Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 50, name: 'Anshudeep', classSec: 'IX-A', activity: 'Science Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 51, name: 'Prachi', classSec: 'X-D', activity: 'Science Quiz Competition', house: 'Patel', position: '1st' },
    { srNo: 52, name: 'Aditi', classSec: 'VIII-E', activity: 'Science Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 53, name: 'Akshita', classSec: 'IX-D', activity: 'Science Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 54, name: 'Himanshu', classSec: 'X-B', activity: 'Science Quiz Competition', house: 'Subhash', position: '2nd' },
    { srNo: 55, name: 'Himank', classSec: 'III-Topaz', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 56, name: 'Jayesh', classSec: 'IV-Opal', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 57, name: 'Kavyanjali', classSec: 'V-Opal', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 58, name: 'Sanvi', classSec: 'V-Opal', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 59, name: 'Harlin Sharma', classSec: 'V-Pearl', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 60, name: 'Yashika', classSec: 'V-Pearl', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 61, name: 'Jiya', classSec: 'V-Topaz', activity: 'Skit Competition', house: 'Subhash', position: '1st' },
    { srNo: 62, name: 'Vanshika', classSec: 'III-Topaz', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 63, name: 'Arnav', classSec: 'IV-Opal', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 64, name: 'Samarth', classSec: 'IV-Opal', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 65, name: 'Nitika', classSec: 'V-Topaz', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 66, name: 'Vani', classSec: 'V-Topaz', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 67, name: 'Rajat', classSec: 'V-Ruby', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
    { srNo: 68, name: 'Mohan', classSec: 'V-Ruby', activity: 'Skit Competition', house: 'Tilak', position: '2nd' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
