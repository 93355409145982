<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Play Ground</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Growing Children need to be provided with avenues to channelize their
                            abundant energy resources. Sports and games provide appropriate learning platform to imbibe
                            essential life skills required for the task of effective growing up.</p>
                        <p class="inner-lead">School has one of the largest playgrounds in the city for outdoor games
                            with facilities as Cricket field, Football and Hockey ground, Athletic Tracks. Besides these
                            facilities we cater to indoor games which are prevalent in the campus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>