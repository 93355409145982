import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ncc',
  templateUrl: './ncc.component.html',
  styleUrls: ['./ncc.component.scss']
})
export class NccComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
