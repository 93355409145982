<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-12">
            <div id="rules">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg inner-card">
                                <h4 class="card-title text-center">Mandatory Public Disclosure</h4>
                                <div class="card-body">
                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">A. GENERAL INFORMATION:</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col" style="width: 50%;">INFORMATION</th>
                                                        <th scope="col" style="width: 45%;">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th> NAME OF THE SCHOOL</th>
                                                        <td>HINDU SENIOR SECONDARY SCHOOL</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th>AFFILIATION NO.</th>
                                                        <td>530235</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th>SCHOOL CODE</th>
                                                        <td>40220</td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th> COMPLETE ADDRESS WITH PIN CODE</th>
                                                        <td>NEW COURT ROAD, BEHIND PANCHAYAT BHAWAN, GOHANA ROAD, SONEPAT
                                                            – 131001 (HARYANA)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <th>PRINCIPAL NAME & QUALIFICATION</th>
                                                        <td>Ms. Hema Chaturvedi <br>M.A., B.Ed., M.Ed.
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>6</th>
                                                        <th>SCHOOL EMAIL ID</th>
                                                        <td>hinducourtroad@gmail.com, 40220@cbseshiksha.in</td>
                                                    </tr>
                                                    <tr>
                                                        <th>7</th>
                                                        <th>CONTACT DETAILS</th>
                                                        <td>0130-2221444 , 2222257</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">B. DOCUMENTS AND INFORMATION:</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col" style="width: 80%;">INFORMATION</th>
                                                        <th scope="col" style="width: 15%;">Download</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th> COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT
                                                            EXTENSION OF AFFILIATION, IF ANY</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/COPIES-OF-AFFILIATION.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th> COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL
                                                            CERTIFICATE, AS APPLICABLE</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/COPIES-OF-SOCIETIES.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF
                                                            APPLICABLE, BY THE STATE GOVT./UT</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/NOC-BY-HARYANA.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th> COPIES
                                                            OF RECOGNITION CERTIFICATE UNDER RTE ACT. 2009. AND IT’S
                                                            RENEWAL IF
                                                            APPLICABLE</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/COPIES-OF-RECOGNITION-CERTIFICATE.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <th>COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE
                                                            NATIONAL BUILDING CODE</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/BUILDING-SAFETY.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>6</th>
                                                        <th>COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE
                                                            COMPETENT AUTHORITY</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/FIRE-SAFETY.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>7</th>
                                                        <th>COPY
                                                            OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR
                                                            AFFILIATION / UPGRADATION
                                                            / EXTENSION OF AFFILIATIONOR SELF CERTIFICATION BY SCHOOL
                                                        </th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/DEO-CERTIFICATE.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>8</th>
                                                        <th>COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES
                                                        </th>
                                                        <td style="font-size: 20px;" class="text-center"><a
                                                                href="/cbse/HEALTH-AND-SANITATION.pdf" target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">C. RESULT AND ACADEMICS:</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col" style="width: 80%;">INFORMATION</th>
                                                        <th scope="col" style="width: 15%;">Download</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th>FEE STRUCTURE OF THE SCHOOL</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/assets/pdf/fees.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th>ANNUAL ACADEMIC CALENDAR</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/CALENDAR.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th> LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)</th>
                                                        <td style="font-size: 20px;" class="text-center"><a
                                                                href="/cbse/SMC.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th>LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/PTA.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <th> LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
                                                            APPLICABILITY</th>
                                                        <td style="font-size: 20px;" class="text-center"><a href="/cbse/RESULT.pdf"
                                                                target="_blank"><i class="fa fa-cloud-download"
                                                                    aria-hidden="true"></i>
                                                            </a></td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">RESULT CLASS: X (SECONDARY)</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col">YEAR</th>
                                                        <th scope="col">NO. OF REGISTERED STUDENTS</th>
                                                        <th scope="col">NO. OF STUDENTS PASSED</th>
                                                        <th scope="col">PASS PERCENTAGE</th>
                                                        <th scope="col">REMARKS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td>2024</td>
                                                        <td>198</td>
                                                        <td>198</td>
                                                        <td>100%</td>
                                                        <td>OUTSTANDING RESULT</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td>2023</td>
                                                        <td>156</td>
                                                        <td>156</td>
                                                        <td>100%</td>
                                                        <td>OUTSTANDING RESULT</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <td>2022</td>
                                                        <td>206</td>
                                                        <td>206</td>
                                                        <td>100%</td>
                                                        <td>OUTSTANDING RESULT</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">RESULT CLASS: XII (SENIOR SECONDARY)</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col">YEAR</th>
                                                        <th scope="col">NO. OF REGISTERED STUDENTS</th>
                                                        <th scope="col">NO. OF STUDENTS PASSED</th>
                                                        <th scope="col">PASS PERCENTAGE</th>
                                                        <th scope="col">REMARKS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <td>2024</td>
                                                        <td>230</td>
                                                        <td>228</td>
                                                        <td>99.13%</td>
                                                        <td>VERY GOOD</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <td>2023</td>
                                                        <td>256</td>
                                                        <td>250</td>
                                                        <td>97.66%</td>
                                                        <td>VERY GOOD</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <td>2022</td>
                                                        <td>215</td>
                                                        <td>212</td>
                                                        <td>98.60%</td>
                                                        <td>VERY GOOD</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">D. STAFF (TEACHING):</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col" style="width: 50%;">INFORMATION</th>
                                                        <th scope="col" style="width: 45%;">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th>PRINCIPAL</th>
                                                        <td>01</td>
                                                    </tr>
                                                    <tr>
                                                        <th rowspan="5" class="align-middle">2</th>
                                                        <th> TOTAL NO. OF TEACHERS</th>
                                                        <td>71</td>
                                                    </tr>
                                                    <tr>

                                                        <th> PGT</th>
                                                        <td>18</td>
                                                    </tr>
                                                    <tr>

                                                        <th> TGT</th>
                                                        <td>18</td>
                                                    </tr>
                                                    <tr>

                                                        <th>PRT</th>
                                                        <td>33</td>
                                                    </tr>
                                                    <tr>

                                                        <th>PET</th>
                                                        <td>2</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th> TEACHERS SECTION RATIO</th>
                                                        <td>1.5 PER SECTION</td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th> DETAILS OF SPECIAL EDUCATOR</th>
                                                        <td>01</td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <th> DETAILS OF COUNSELLOR AND WELNESS TEACHER</th>
                                                        <td>01</td>
                                                    </tr>
                                                    <tr>
                                                        <th>6</th>
                                                        <th>Staff List</th>
                                                        <td style="font-size: 20px;" class="text-center"><a
                                                                href="/cbse/Staff.pdf" target="_blank"><i
                                                                    class="fas fa-eye"></i>
                                                            </a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row w-100">
                                        <div class="col-lg-12 col-md-12">
                                            <h4 class="text-warning fw-bold">E. SCHOOL INFRASTRUCTURE:</h4>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 5%;">S.No.</th>
                                                        <th scope="col" style="width: 50%;">INFORMATION</th>
                                                        <th scope="col" style="width: 45%;">Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>1</th>
                                                        <th>TOTAL CAMPUS AREA OF THE SCHOOL</th>
                                                        <td>24281.1384</td>
                                                    </tr>
                                                    <tr>
                                                        <th>2</th>
                                                        <th>NO. AND SIZE OF THE CLASSROOMS</th>
                                                        <td>66, 24X18</td>
                                                    </tr>
                                                    <tr>
                                                        <th>3</th>
                                                        <th> NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS</th>
                                                        <td>8, 50X20</td>
                                                    </tr>
                                                    <tr>
                                                        <th>4</th>
                                                        <th> INTERNET FACILITY (Y/N)</th>
                                                        <td>YES</td>
                                                    </tr>
                                                    <tr>
                                                        <th>5</th>
                                                        <th> NO. OF GIRLS TOILETS</th>
                                                        <td>32</td>
                                                    </tr>
                                                    <tr>
                                                        <th>6</th>
                                                        <th> NO. OF BOYS TOILETS</th>
                                                        <td>76</td>
                                                    </tr>
                                                    <tr>
                                                        <th>7</th>
                                                        <th>Youtube Affiliation Video Link</th>
                                                        <td style="font-size: 20px;" class="text-center"><a
                                                                href="https://youtu.be/HXTx5zjKf0I?si=592RsDQhWcShaL2F" target="_blank"><i
                                                                    class="fas fa-eye"></i>
                                                            </a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div class="row mt-5"><a href="/cbse/Mandatory-Disclosure-Details-_-SARAS-4.0.pdf" target="_blank">Mandatory Disclosure Link</a></div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>