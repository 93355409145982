<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Sports</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Sports play an important role in the overall development of a child. The
                            academic excellence is incomplete without a robust outdoor calendar that prepares the young
                            minds to face the challenges head on and develop mental and physical alertness; and thus
                            become highly productive individuals who can play a greater and crucial role in nation
                            building. However, to maximize the positive benefits that sports have for children, a
                            balance must be found that matches the child’s maturity, interests and skills with their
                            sports participation.
                        </p>
                        <p class="inner-lead">Sports encourage teamwork and being part of a team might help your child
                            learn to interact with peers in a positive way. Sports can help your child build
                            self-confidence and build leadership skills as well.</p>
                        <p class="inner-lead">It is well known that children who participate in sports can also reap
                            emotional benefits when they are given positive sporting role models and experiences. From
                            sports children learn the value of practice and the challenge of competition, and these are
                            lessons that can be applied to other non-sporting parts of their lives. Given the right
                            examples, children can also use sports to create a healthy self-image.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>