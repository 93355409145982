import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/showcase/showcase.service';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.scss']
})
export class AlumniComponent implements OnInit {
sub: Subscription;
  constructor(private showcaseService: ShowcaseService,) { }

  ngOnInit(): void {
    this.showcaseService.hideShowcase();
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }
}
