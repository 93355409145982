import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './screens/home/home.component';
import { CbseComponent } from './screens/cbse/cbse.component';
import { RegistrationComponent } from './screens/registration/registration.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', loadChildren: './modules/about/about.module#AboutModule' },
  { path: 'academic', loadChildren: './modules/academic/academic.module#AcademicModule' },
  { path: 'contact-us', loadChildren: './modules/contact/contact.module#ContactModule' },
  { path: 'facility', loadChildren: './modules/facility/facility.module#FacilityModule' },
  { path: 'rules', loadChildren: './modules/rules/rules.module#RulesModule' },
  { path: 'beyond', loadChildren: './modules/beyond/beyond.module#BeyondModule' },
  { path: 'achievement', loadChildren: './modules/achievement/achievement.module#AchievementModule' },
  { path: 'alumni', loadChildren: './modules/alumni/alumni.module#AlumniModule' },
  { path: 'mandatory-public-disclosure', component:CbseComponent },
  { path: 'registration', component:RegistrationComponent },
  {path:'**',redirectTo:'',pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
