<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Medical Facilities</h4>
                    <div class="card-body ">
                        <p class="inner-lead">An fully equipped medical room has a doctor on duty all through the school
                            hours. Skilled professionals are in attendance to ensure that all emergencies are treated
                            with utmost care and attention. Regular medical checkups are conducted and detailed medical
                            records of students are maintained once / twice a year (if needed) along with valuable
                            suggestions by the doctors. Students are mentored on the various aspects of diseases (Health
                            Education), first aid, life style, diet management techniques and stress management (aided
                            with Yoga & Meditation) .The students are also oriented about the importance of developing
                            immunity.</p>

                        <h6>Hygiene and Sanitation</h6>
                        <p class="inner-lead">Highest standards of hygiene and cleanliness are maintained. Only mineral
                            water is supplied for drinking purpose through numerous water dispensers’ placed at
                            convenient locations all over the campus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>