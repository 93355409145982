import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcademicsComponent } from 'src/app/screens/achievement/academics/academics.component';
import { AchievementComponent } from 'src/app/screens/achievement/achievement.component';
import { CoCurricularComponent } from 'src/app/screens/achievement/co-curricular/co-curricular.component';
import { InterhouseComponent } from 'src/app/screens/achievement/interhouse/interhouse.component';
import { OlympiadComponent } from 'src/app/screens/achievement/olympiad/olympiad.component';
import { StarsComponent } from 'src/app/screens/achievement/stars/stars.component';
import { StateLevelComponent } from 'src/app/screens/achievement/state-level/state-level.component';
import { SportsComponent } from 'src/app/screens/achievement/sports/sports.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AchievementComponent,
    AcademicsComponent,
    StarsComponent,
    CoCurricularComponent,
    OlympiadComponent,
    InterhouseComponent,
    SportsComponent,
    StateLevelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: AchievementComponent, children: [
          { path: 'academics', component: AcademicsComponent },
          { path: 'stars', component: StarsComponent },
          { path: 'co-curricular', component: CoCurricularComponent },
          { path: 'olympiad', component: OlympiadComponent },
          { path: 'interhouse', component: InterhouseComponent },
          { path: 'sports', component: SportsComponent },
          { path: 'state-level', component: StateLevelComponent },
        ]
      }
    ])
  ]
})
export class AchievementModule { }
