<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Hostel Facilities</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Our hostels are fully provided with beds, wardrobes and study desks,
                            offering our students a clean, comfortable and homely environment. A spacious common room
                            offers computers, LCD TV and other entertainment facilities to students. Guided study hours
                            in the evenings are maintained. Nutritious and healthy meals are served in a neat and
                            hygienic environment. The House Parents of the boys and girls hostels provide a caring
                            environment to all students. In addition, experienced supervisors ensure special care of
                            younger students. Medical facility is available in the campus round the clock.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>