import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toddler',
  templateUrl: './toddler.component.html',
  styleUrls: ['./toddler.component.scss']
})
export class ToddlerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
