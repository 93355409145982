import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from 'src/app/screens/academic/overview/overview.component';
import { AcademicComponent } from 'src/app/screens/academic/academic.component';
import { CoursesComponent } from 'src/app/screens/academic/courses/courses.component';
import { KindergartenComponent } from 'src/app/screens/academic/kindergarten/kindergarten.component';
import { LearningComponent } from 'src/app/screens/academic/learning/learning.component';
import { ToddlerComponent } from 'src/app/screens/academic/toddler/toddler.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AcademicComponent,
    ToddlerComponent,
    CoursesComponent,
    KindergartenComponent,
    LearningComponent,
    OverviewComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {path:'', component:AcademicComponent, children:[
        {path:'overview', component:OverviewComponent},
        {path:'toddler', component:ToddlerComponent},
        {path:'courses', component:CoursesComponent},
        {path:'learning', component:LearningComponent},
        {path:'kindergarten', component:KindergartenComponent},
      ]}
    ])
  ]
})
export class AcademicModule { }
