<div id="about-us" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="home-title">Explore Our School</h1>
                <p>Discover a world of learning tailored to foster curiosity and growth at every educational milestone.</p>
            </div>
            
            <div class="col-12 col-md-4 mb-4" *ngFor="let item of event">
                <div class="card">
                    <div class="image-container"><img [src]="item.imgUrl" class="img-fluid w-100" [alt]="item.title"></div>
                    <div class="card-body overlay">
                        <p>{{item.title}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>