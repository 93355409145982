<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Academic Achievement</h4>
                    <div class="card-body ">
                        <div class="row mb-4">
                            <div class="col-12 col-md-6 mb-5" *ngFor="let data of achievement">
                                <div class="testimonial mx-auto">
                                    <div class="testimonial-body">
                                        <p></p>
                                    </div>
                                    <div class="testimonial-footer">
                                        <img [src]="data.imgUrl" appLazyload alt="Student" class="mx-auto" />
                                        <h3>Name: {{data.name}}</h3>
                                        <h4>Class: {{data.class}}</h4>
                                        <h3>Position: {{data.position}}</h3>
                                        <h3>Percentage: {{data.percentage}}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 text-center mb-3"><img src="/assets/img/achievement/academic/1.jpg" class="img-fluid" alt=""></div>
                            <div class="col-12 col-md-6 text-center mb-3"><img src="/assets/img/achievement/academic/2.jpg" class="img-fluid" alt=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>