<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>Academic</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/overview"><i class="fa fa-angle-right" aria-hidden="true"></i>Academic Overview</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/courses"><i class="fa fa-angle-right" aria-hidden="true"></i>Courses For XI-XII</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/toddler"><i class="fa fa-angle-right" aria-hidden="true"></i>Toddler House</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/kindergarten"><i class="fa fa-angle-right" aria-hidden="true"></i>Key Areas in Kindergarten</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/academic/learning"><i class="fa fa-angle-right" aria-hidden="true"></i>Concept Learning</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>