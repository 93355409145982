<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>Facility</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/play-ground"><i class="fa fa-angle-right" aria-hidden="true"></i>Play Ground</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/medical"><i class="fa fa-angle-right" aria-hidden="true"></i>Medical Facilities</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/smart-class"><i class="fa fa-angle-right" aria-hidden="true"></i>Smart Class</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/sa-re-ga-ma"><i class="fa fa-angle-right" aria-hidden="true"></i>Sa Re Ga Ma</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/ncc"><i class="fa fa-angle-right" aria-hidden="true"></i>N.C.C.</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/house"><i class="fa fa-angle-right" aria-hidden="true"></i>House System</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/library"><i class="fa fa-angle-right" aria-hidden="true"></i>Library</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/dance"><i class="fa fa-angle-right" aria-hidden="true"></i>Dance Studio</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/sports"><i class="fa fa-angle-right" aria-hidden="true"></i>Sports</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/cafeteria"><i class="fa fa-angle-right" aria-hidden="true"></i>Cafeteria</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/labs"><i class="fa fa-angle-right" aria-hidden="true"></i>Labs</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/facility/hostel"><i class="fa fa-angle-right" aria-hidden="true"></i>Hostel Facilities</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>