<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Damage to School Property</h4>
                    <div class="card-body ">
                        <ul>
                            <li>
                                <p class="inner-lead">School is the Temple of Knowledge. Every student is expected to
                                    behave with reverence to this Temple of Knowledge. Keep the school, premises and
                                    properties neat and clean without any damage.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Students are strictly forbidden to write or scribble on walls,
                                    desks, benches etc. or throw paper or spill ink around the classrooms or cause
                                    damage to the school property such as laboratory articles, library books, and
                                    furniture etc.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Damage done to the school property will be fully compensated,
                                    and such cases will be dealt strictly.</p>
                            </li>
                            <li>
                                <p class="inner-lead">The school authorities will be constrained to resort to stern
                                    actions to curb all kinds of destructive tendencies in the students.</p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>