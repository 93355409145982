<app-bulletin></app-bulletin>
<app-about-us></app-about-us>
<app-message></app-message>
<app-why></app-why>
<app-curriculum></app-curriculum>
<app-infra></app-infra>
<app-events></app-events>
<!-- <app-alumni></app-alumni> -->

<div class="modal" tabindex="-1" id="popupModal" role="dialog">
    <div class="modal-dialog mx-auto">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div id="popupCarousel" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item" *ngFor="let img of popups, let i=index"
                            [ngClass]="(i == 0 ) ? 'active' : ''" data-bs-interval="5000">
                            <img [src]="img" class="d-block w-100">
                        </div>
                    </div>
                    <a class="carousel-control-prev" type="button" data-bs-target="#popupCarousel" role="button"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </a>
                    <a class="carousel-control-next" type="button" data-bs-target="#popupCarousel" role="button"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>