<div id="bulletin" class="py-5">
    <div class="container">
        <div class="row bullet bg-light">
            <div class="col-3 col-md-2 bullet-1">
                <h5 class="text-center">Announcements</h5>
                <span class=""></span>
            </div>
            <div class="col-9 col-md-10 bullet-2">
                <marquee behavior="scroll" direction="left" class="bulletinMarque">Registration Open For classes Nursery to 9th and 11th <a href="/assets/img/popup/1.png" target="_blank" class="text-decoration-none">Click Here</a></marquee>
            </div>
        </div>
    </div>
</div>
