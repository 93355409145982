<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">NCC</h4>
                    <div class="card-body ">
                        <div class="row text-center">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/ncc/1.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/ncc/2.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/ncc/3.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/ncc/4.jpg" alt="" class="img-fluid rounded-4"></div>
                        </div>
                        <p class="inner-lead">To foster the growth of character, discipline and leadership qualities
                            among the
                            students, the school has the provision of NCC under 12 Haryana Battalion NCC.
                            It was headed by Mr. Rajender Paliwal in 1992 followed by Mr. Suryakant from
                            1999 to 2023. At present it is under the supervision of Mr. Amit Mittal. Every
                            year NCC cadets attend CATC camp where they are provided Drill, Weapon
                            Training, Field Craft, Battle Craft, Map Reading to get NCC ‘A’ certificate </p>

                        <h4 class="card-title text-center">Testimonial</h4>
                        <div class="col-md-12 col-xl-6 d-flex align-items-stretch mt-4">
                            <div class="card1 mx-md-2 mx-lg-0">
                                <div class="card-img text-center w-100">
                                    <img src="/assets/img/facility/ncc/5.jpg" class="img-fluid shadow" alt="A+ Grade">
                                </div>
                                <div class="card-body shadow">
                                    <h5 class="card-title"> One of our students Mr. Sudeep Vashishth has been selected in NDA and working as Major in the Army.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>