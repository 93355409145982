<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Toddler House</h4>
                    <div class="card-body ">
                        <p class="inner-lead text-center"><i>“Childhood is like the sun which appears at dawn or a
                                flower just beginning to bloom. Education cannot be effective unless it helps a child to
                                open up himself to life.”</i></p>

                        <h6>OUR Ethos</h6>
                        <ol type="1">
                            <li>
                                <p class="inner-lead">To nurture the child step-by-step with a sensitivity towards his
                                    absorbent mind and stages of development.</p>
                            </li>
                            <li>
                                <p class="inner-lead">An international curriculum in sync with the natural milestones of
                                    the child.</p>
                            </li>
                            <li>
                                <p class="inner-lead">The Montessori Method is applied for the complete evolution of the
                                    personality. The ultimate psychic integration of the mind and the body is possible
                                    with our highly aware and prepared environment.</p>
                            </li>
                            <li>
                                <p class="inner-lead">All the aspects of Gardener’s multi intelligence theory are
                                    nurtured and addressed to, in simple endearing ways.</p>
                            </li>
                            <li>
                                <p class="inner-lead">A curriculum which caters to the pluralistic multi-cultural
                                    society and inculcates joy in mutual celebrations of festivals from all over the
                                    world A curriculum which caters to the pluralistic multi-cultural society and
                                    inculcates joy in mutual celebrations of festivals from all over the world.</p>
                            </li>
                            <li>
                                <p class="inner-lead">An environment, a little more than home, where there are no “NO”s
                                    but a guided confidence of adaptation and mastery over the environment.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Each and every detail of the set up has an aware purpose for the
                                    development of the child.</p>
                            </li>
                        </ol>

                        <h6>No one understands the child like we do</h6>
                        <p class="inner-lead">Our Pre Primary wing is a complete Montessori wing with great curriculum
                            for the Global citizens of the future. Here each classroom is equipped with materials
                            pertaining to varied aspects of Intelligence and personality. The curriculum is based on
                            stages of development, is universally accepted, with Montessori guidelines and works towards
                            harmonious coexistence of all and evolving of human consciousness.</p>

                        <h6>The most secure place to be in</h6>
                        <p class="inner-lead">Our Toddler House is a cozy nest for the first spread of the wings of the
                            child. It is activity based developmental curriculum in a snug environment. Here adaptation
                            is the key; the child adapts to the world around him; the toddler house adapts to the
                            society we are in; we together adapt Friedrich Froebel’s play methods to achieve the highest
                            potential of the child in any specific environment and space.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>