import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 15,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      },
      1160: {
        items: 3
      }
    },
    nav: true
  }
  event: any[] = [
    {
      date: '13',
      month: 'Jan`24',
      title: 'Admissions Open',
      imgUrl: '/assets/img/events/admission3.jpg'
    },
    {
      date: '13',
      month: 'Jan`24',
      title: 'Admissions Open',
      imgUrl: '/assets/img/events/admission2.jpg'
    },
    {
      date: '13',
      month: 'Jan`24',
      title: 'Admissions Open',
      imgUrl: '/assets/img/events/admission1.jpg'
    },
    // {
    //   date: '17',
    //   month: 'Sep`24',
    //   title: 'Election Notice',
    //   imgUrl: '/assets/img/events/election-notice.jpg'
    // },
    // {
    //   date: '27',
    //   month: 'Jul`24',
    //   title: 'Advertisements',
    //   imgUrl: '/assets/img/events/advertisement.jpg'
    // },
    // {
    //   date: '11',
    //   month: 'Jul`24',
    //   title: 'School Managing Committee',
    //   imgUrl: '/assets/img/events/smc.jpg'
    // },
    {
      date: '11',
      month: 'Jul`24',
      title: 'Janamashtami Celebration',
      imgUrl: '/assets/img/events/janamashtami.jpg'
    },
    {
      date: '10',
      month: 'Jul`24',
      title: 'Yellow Day Celebration',
      imgUrl: '/assets/img/events/yellow-day.jpg'
    },
    {
      date: '19',
      month: 'Jan`24',
      title: 'Links For School YouTube Page',
      imgUrl: '/assets/img/events/yotube.jpg'
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
