<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">LIST OF PRIZE WINNERS OF INTERHOUSE ACTIVITIES 2023-2024</h4>
                    <div class="card-body ">

                        <div class="overflow-x-scroll">
                            <table class="table table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Sr No.</th>
                                        <th>Name of Student</th>
                                        <th>Class/Sec.</th>
                                        <th>Name of Activity</th>
                                        <th>House</th>
                                        <th>Position</th>
                                    </tr>
                                    <tr *ngFor="let student of studentsActivities">
                                        <td>{{ student.srNo }}</td>
                                        <td>{{ student.name }}</td>
                                        <td>{{ student.classSec }}</td>
                                        <td>{{ student.activity }}</td>
                                        <td>{{ student.house }}</td>
                                        <td>{{ student.position }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>