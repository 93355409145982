<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Late Comers</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Students who are not present for the assembly for three consecutive days
                            will be treated as late comers and such students may be asked to go back home immediately.
                            Delay by the conveyance is not an excuse in this regard.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>