<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">School Management Committee(SMC)</h4>
                    <div class="card-body ">
                        <img src="/assets/img/about/SMC2.png" class="img-fluid w-100 h-auto" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>