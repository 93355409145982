<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Labs</h4>
                    <div class="card-body ">
                        <h6>Science Labs</h6>
                        <p class="inner-lead">Science lab give students opportunity to interact with scientific
                            materials and tools. This helps to understand science concepts and
                            developed critical thinking and problem solving skills.</p>

                        <h6>Physics Lab </h6>
                        <img src="/assets/img/facility/labs/physics.jpg" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">Physics lab in spacious hall that can accommodate 30 to 35 students at a
                            time. This lab equipped with latest equipments catering to the +2 syllabus where students
                            carry out their experiments in an innovative environment.</p>

                        <h6>Chemistry Lab </h6>
                        <img src="/assets/img/facility/labs/chemistry.jpg" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">Chemistry lab has all the necessary equipments for preparation of samples
                            and
                            standards for various analysis of liquid and solid samples. It is a well ventilated hall
                            which provide clear and hygienic ambience to carry out experiments.</p>

                        <h6>Biology Lab </h6>
                        <img src="/assets/img/facility/labs/bio.jpg" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">The biology lab is fully equipped with all the required specimens to
                            provide first hand knowledge to the students about the subject. It offers various
                            opportunities for students to think creatively and explore their interest. </p>

                        <h6>Social Science Lab</h6>
                        <img src="/assets/img/facility/labs/social-science.png" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">Social Science lab act as an activity room for students as it combines
                            academic experience with only knowledge making learning effective, lively and fun. This lab
                            provides children various options to learn better and to increase their cognitive and
                            competitive learning.</p>

                        <h6>Atal Tinkering Lab </h6>
                        <img src="/assets/img/facility/labs/atal.jpg" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">Atal tinkering lab is a Central Government of India initiative to create
                            an environment of scientific temperament innovation creativity amongst Indian students. It
                            is a step towards in India that will embrace Indian courage innovative ideas and. Lab is
                            annually upgraded with the latest gadget. It is a spacious hall that can accommodate 50 to
                            60 students at a time.</p>

                        <h6>Mathematics Lab </h6>
                        <img src="/assets/img/facility/labs/maths.jpg" alt="" class="img-fluid rounded-4 mb-3">
                        <p class="inner-lead">In order to make the mathematical learning interesting and joyful separate
                            mathematics lab has been set up. This lab is fully with all the required materials needed to
                            have the student concept through relevant activities with further helps in developing the
                            skills and awareness about new concept and methodologies.</p>

                        <h6>Computer Lab </h6>
                        <p class="inner-lead">Computer education forms integrated part of the syllabus for all classes
                            to 10th and comes as an optional for classes 11th and to cater this need the school has
                            three user friendly computer labs</p>

                        <ul>
                            <li>
                                <p class="inner-lead fw-bold">Primary Computer Lab </p>
                                <img src="/assets/img/facility/labs/primaryComp.jpg" alt="" class="img-fluid rounded-4 mb-3">
                            </li>
                            <li>
                                <p class="inner-lead fw-bold">Middle Computer Lab</p>
                                <img src="/assets/img/facility/labs/middleComp.jpg" alt="" class="img-fluid rounded-4 mb-3">
                            </li>
                            <li>
                                <p class="inner-lead fw-bold">Senior Secondary Computer Lab</p>
                                <img src="/assets/img/facility/labs/seniorComp.jpg" alt="" class="img-fluid rounded-4 mb-3">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>