<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Sports</h4>
                    <div class="card-body ">
                        <ol>
                            <li *ngFor="let item of sports;">
                                <p>{{ item.title }}</p>
                                <div class="d-flex justify-content-between" *ngIf="item.img && item.img2">
                                    <img [src]="item.img" class="img-fluid rounded" alt="Sports">
                                    <img [src]="item.img2" class="img-fluid rounded" alt="Sports">
                                </div>
                                <img [src]="item.img" class="img-fluid rounded" alt="Sports" *ngIf="item.img && !item.img2">
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>