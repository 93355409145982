<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Life At Hindu Sr. Sec. School</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Life at Hindu Sr. Sec School is about giving your child the best you
                            want as a parent. From a strong academic foundation to the development of your child’s
                            physical, spiritual and emotional personality, the School way is about holistic development
                            in its true sense. Our two decades of academic excellence is an open testimonial one can
                            refer to. We understand that after home, the single biggest influence is the institution
                            that you choose for your child’s learning, making it all the more crucial for you to take
                            this decision with great care.</p>
                        <h6>Experiential Learning</h6>
                        <p class="inner-lead">At Hindu Sr. Sec School, we ensure that along with a high caliber of
                            academics our children inculcate winning habits from a young age. We do this through various
                            character building activities that strengthen their moral fiber and help them face the
                            rigors of academia and life.</p>
                        <p class="inner-lead">The Hindu Sr. Sec School way is defined as KASSM – Knowledge, Attitude,
                            Skills, and Social & Moral Values. Values with sound knowledge and the wisdom to use it
                            constructively is what provide the differential factor to our students at Hindu Sr. Sec
                            School. Our institutions provide a learning environment that is adaptable and flexible,
                            facilitating potential changes in the higher education pattern.</p>
                        <p class="inner-lead">This is done with an objective to support the new academic paradigm that
                            provides learning for students in a manner most suited to them.</p>

                        <h6>Sports</h6>
                        <p class="inner-lead">Sports play an important role in the overall development of a child. The
                            academic excellence is incomplete without a robust outdoor calendar that prepares the young
                            minds to face the challenges head on and develop mental and physical alertness; and thus
                            become highly productive individuals who can play a greater and crucial role in nation
                            building. However, to maximize the positive benefits that sports have for children, a
                            balance must be found that matches the child’s maturity, interests and skills with their
                            sports participation.</p>
                        <p class="inner-lead">Sports encourage teamwork and being part of a team might help your child
                            learn to interact with peers in a positive way. Sports can help your child build
                            self-confidence and build leaderships skills as well. In order to encourage the culture of
                            sports in Hindu Sr. Sec School.</p>
                        <p class="inner-lead">It is well known that children who participate in sports can also reap
                            emotional benefits when they are given positive sporting role models and experiences. From
                            sports children learn the value of practice and the challenge of competition, and these are
                            lessons that can be applied to other non-sporting parts of their lives. Given the right
                            examples, children can also use sports to create a healthy self-image.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>