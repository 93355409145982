import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-curricular',
  templateUrl: './co-curricular.component.html',
  styleUrls: ['./co-curricular.component.scss']
})
export class CoCurricularComponent implements OnInit {
  activitiesList = [
    { activityName: 'Quiz Competition', students: 'Jhalak and Bhavishay Rana', position: '1st' },
    { activityName: 'Clay Modeling', students: 'Ramanuj', position: 'Consolation' },
    { activityName: 'Sketching on the spot', students: 'Dikshant (4th group)', position: 'Consolation' },
    { activityName: 'Group Dance', students: 'Ritika/B', position: '3rd' },
    { activityName: 'Fun Games (Girls)', students: 'Anushka', position: '3rd' },
    { activityName: 'Fun Games (Boys)', students: 'Vinay', position: '3rd' },
    { activityName: 'Sketching on the spot', students: 'Sohil (3rd Group)', position: '1st' },
    { activityName: 'Sketching on the spot', students: 'Deepanshi (3rd Group)', position: 'Consolation' },
    { activityName: 'Sketching on the spot', students: 'Mukul (2nd Group)', position: '3rd' },
    { activityName: 'Solo Dance', students: 'Yashika (1st Group)', position: '3rd' },
    { activityName: 'Klash decoration', students: 'Rakhi (4th Group)', position: '1st' },
    { activityName: 'Skit Competition', students: '11th and 12th students', position: '2nd' },
    { activityName: 'Solo Dance', students: 'Anshika (2nd Group)', position: 'Consolation' },
    { activityName: 'Solo Dance', students: 'Vandana (3rd Group)', position: 'Consolation' }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
