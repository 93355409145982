<div id="beyond">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Personal And Social Education</h4>
                    <div class="card-body ">
                        <p class="inner-lead">At Hindu Senior Secondary School we attach great importance to the
                            identification and achievement of personal goals; we lay special stress on planning,
                            implementation and evaluation of decisions; developing self-esteem; managing stress and
                            coping with change and conflict-traits that are essential in self-management skills and that
                            underpin a healthy and active lifestyle. Through participation in classroom interactions,
                            sporting, recreational and other physical activities, students develop and practice these
                            skills. Students who possess sound self-management skills are better able to identify and
                            avoid potential health risks, enhance their mental health and well-being, as well as plan
                            for their future. Effective interpersonal skills are also essential for participation in
                            meaningful and fulfilling relationships in family, school, recreation, work and community
                            contexts. Interpersonal skills such as assertive communication, negotiation, conflict
                            resolution, cooperation and leadership enable students to act responsibly and contribute
                            effectively to groups and teams. Training in these areas is part of the personal development
                            programs at the School.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>