<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Library Rules</h4>
                    <div class="card-body ">
                        <ul>
                            <li>
                                <p class="inner-lead">Silence must be maintained in the library at all times.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Personal belongings must not be taken to the Library.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Students must maintain a record of the books they have been issued and read, from the school library.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Library books will be issued only in the library period and for one week only.</p>
                            </li>
                            <li>
                                <p class="inner-lead">All the books should be returned a week before the exams i.e. Half Yearly, Final & Pre – Board.</p>
                            </li>
                            <li>
                                <p class="inner-lead">If a library book is lost or the pages are torn, it should be replaced or the student will be charged the cost of the book.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>