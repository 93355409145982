import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-infra',
  templateUrl: './infra.component.html',
  styleUrls: ['./infra.component.scss']
})
export class InfraComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 15,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 300,
    navText: ['<', '>'],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      },
      1160: {
        items: 3
      }
    },
    nav: true
  }

  infra: any[] = [
    // {
    //   imgUrl: '/assets/img/infra/classroom.jpg',
    //   title: 'Smart Class',
    //   desc: 'Our Audio Visual Smart classroom is equipped to give practical (audio and visual) experience to the students. Hindu Senior Secondary School offers futuristic facilities where Smart Class technology is used, to the maximum advantage...',
    //   route: '/facility/smart-class'
    // },
    {
      imgUrl: '/assets/img/infra/lab.jpg',
      title: 'Laboratories',
      desc: 'Science lab give students opportunity to interact with scientific materials and tools. This helps to understand better understand science concepts and developed critical thinking and problem solving skills...',
      route: '/facility/labs'
    },
    // {
    //   imgUrl: '/assets/img/infra/library.jpg',
    //   title: 'Library',
    //   desc: 'The school houses a huge Library space with Senior and Junior Sections, imaginatively stocked with about 16000 titles that cover fiction and non- fiction in all genres and subjects. It also has a stock of multimedia materials...',
    //   route: '/facility/library'
    // },
    {
      imgUrl: '/assets/img/infra/ncc.jpg',
      title: 'NCC',
      desc: 'To foster the growth of character, discipline and leadership qualities among the students, the school has the provision of NCC under 12 Haryana Battalion NCC. It was headed by Mr. Rajender Paliwal in 1992 followed by Mr. Suryakant from 1999 to 2023.',
      route: '/facility/ncc'
    },
    {
      imgUrl: '/assets/img/facility/house/1.jpg',
      title: 'House System',
      desc: 'For inculcating and developing the qualities of leadership, public speaking and organizational skills among the students, the school has been divided into four houses i.e. Azad, Patel, Subhash and Tilak.Points are awarded to the houses...',
      route: '/facility/house'
    },
    {
      imgUrl: '/assets/img/infra/sports.jpg',
      title: 'Sports',
      desc: 'Sports play an important role in the overall development of a child. The academic excellence is incomplete without a robust outdoor calendar that prepares the young minds to face the challenges head on and develop mental and physical...',
      route: '/facility/sports'
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

  redirect(url: string) {
    window.open(url);
  }

}
