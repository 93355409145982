import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.scss']
})
export class AlumniComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    margin: 15,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['<', '>'],
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      },
      1160: {
        items: 1
      }
    },
    nav: false
  }

  alumni: { name: string, description: string, videoPath: string, designation: string }[] = [
    { 
      name: 'Tamanna', 
      description: 'My name is Tamanna. I have recently passed 12th class from Hindu Sr. Sec. School which offers a lot of facility to students and well experienced teachers help a lot to students not only in academics but also in co-curricular activities.', 
      videoPath: '/assets/img/alumni/tamanna.mp4', 
      designation: 'Govt. Job' ,
    },
    { 
      name: 'Harsh', 
      description: 'Hi everyone, My name is Harsh. I am a basketball player. My school always appreciates and motivates me to upgrade my game which helps me to be the part of Nationals.', 
      videoPath: '/assets/img/alumni/harsh.mp4', 
      designation: 'Founder, Company' 
    },
    { 
      name: 'Arpit Pandey', 
      description: 'Feeling proud to say that I have completed my schooling from HSSS. I admire most support from everyone at every step. My teachers help me a lot in brightening my future. I am thankful to HSSS because it gave me a lot of opportunities to develop my overall personality and play my part in the betterment of society. The teachers are very learned and experienced in their subjects and I was very lucky to get into their vicinity. They not only taught us the subjects but they taught us the philosophy of life which we had to face in the world.', 
      videoPath: '/assets/img/alumni/arpit.mp4', 
      designation: 'College Lecturer' 
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
