<div id="about-us" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 my-auto">
                <h5 class="home-title sub-title"><span>About Us</span></h5>
                <h1 class="home-title">Hindu Senior Secondary School, Sonepat</h1>
                <p>Hindu Senior Secondary School, New Court Road, Sonepat is a highly progressive, English medium,
                    Co-educational school, affiliated to Central Board of Secondary Education, New Delhi. It was
                    established in 1990 with a view to instill moral and spiritual values in the children. In order to
                    fulfil the dream of our founder into reality the school authorities make healthy plans right from
                    the beginning of the academic session to cover various aspects of a student's life.
                    <!-- The school is administered by The Sonepat Hindu
                    Educational and charitable society which is non-profitable organization, established in the year 1914
                    with the aimtoimplement quality education in sonepat and surrounding areas. It is one of
                    the oldestand highly refuted educational societies in northen India. At present the society is running
                    16 educational institutions having their own campus with nearly 22,000 students and one Charitable
                    Hospital. -->
                </p>
                <div class="readMore text-center">
                    <a routerLink="/about/overview" class="btn px-5 mb-4 mb-md-0">Read More &nbsp; &nbsp;<i
                            class="fa-solid fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-12 col-md-6 text-center">
                <img src="/assets/img/home-about/1.jpg" alt="" class="img-fluid rounded-3 h-100">
            </div>
        </div>
    </div>
</div>