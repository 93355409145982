<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Punctuality</h4>
                    <div class="card-body ">
                        <ul>
                            <li>
                                <p class="inner-lead">Students are expected to be regular and punctual and once inside
                                    the school premises, they shall not go out of the school campus without the
                                    permission of the Principal.</p>
                            </li>
                            <li>
                                <p class="inner-lead">At the first bell the students shall line up to go for the
                                    assembly in perfect silence. At the second bell, all students should assemble in the
                                    school yard in order and in silence. After the assembly they shall go to the
                                    respective classes in perfect order and silence.</p>
                            </li>
                            <li>
                                <p class="inner-lead">During intervals and play time the students shall not go out of
                                    the school premises. They should avoid shouting, quarreling and the use of rude
                                    language in the class rooms and school premises.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Running inside the school building, especially through the
                                    corridors and stair cases, is strictly prohibited.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Regular violation will invite serious remedial measures.</p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>