<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Concept Learning</h4>
                    <div class="card-body ">
                        <p class="inner-lead">The school offers a broad and balanced curriculum which is designed to
                            match the educational needs of the children within the parameters of the CBSE examination
                            frame work. Hindu Senior Secondary School offers a ‘holistic, quality education’. Our objective is to enrich students’ knowledge to enable them to take
                            their Board exams with confidence and effectiveness. A carefully planned academic syllabus
                            ensures that a child enjoys the years spent in learning and discovery, and acquires a sound
                            understanding of concepts in Mathematics Science & Humanities and develops high
                            communication skills. The syllabus is enriched by teacher designed worksheets and subject
                            integrated projects. Emphasis is placed on learning through curriculum related trips, talks
                            by invited speakers and through films.</p>

                        <h6>Pre-Primary Education</h6>
                        <p class="inner-lead">In the pre-primary years we follow a non-formal approach to learning.
                            Children are encouraged to explore the environment around them and feel their presence in
                            it, through projects which integrate art, craft, music and dance. Activities are designed to
                            develop their finer motor skills and promote their sensorial development. Due emphasis is
                            also laid on developing the communication skills.</p>

                        <h6>The Junior School Grade-I to V</h6>
                        <p class="inner-lead">The Junior School dwells on the spirit of exploration and enquiry.
                            Emphasis is laid on acquiring sound language skills, clarity of mathematical concepts and
                            the discovery and understanding of scientific principles. Through extensive project work,
                            they are given awareness of the richness of the diverse history and culture of India. The
                            children also acquire all the building blocks of scholarship, along with good study habits
                            and a love for learning. There are no formal examinations for the children of pre-primary &
                            junior school. Teacher’s evaluation and assessments are continuous and ongoing and form the
                            basis of a child’s performance in a class. Light home work is given every day to reinforce
                            the concepts taught in class.</p>

                        <h6>The Senior School-Classes VI to XII</h6>
                        <p class="inner-lead">The Senior School aims at consolidating and building the students’
                            academic and social growth. The emphasis is on investigation and conceptualization as they
                            begin the first phase of preparation for the CBSE examinations.</p>

                        <h6>Homework</h6>
                        <p class="inner-lead">Homework is set every day during term time and is considered to be an
                            integral part of the learning process in encouraging independent study. The time students
                            are expected to devote to their homework increases from six hours a week in Class VI to 14
                            hours per week in Class X. Students preparing for Board exams will be expected to spend
                            considerably longer time in later years.</p>

                        <h6>Assessment</h6>
                        <p class="inner-lead">Regular assessment of students’ work is considered crucial to the
                            educational process. Performance is discussed both with the parents and the students.
                            Students’ performance in all subjects is reviewed each term whilst assessments under
                            examination conditions take place four times every year.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>