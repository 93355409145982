import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  src:string = '/assets/pdf/staff.pdf'
  isLoading=true;
  constructor() { }

  ngOnInit(): void {
  }

  hideLoader(){
    this.isLoading=false;
  }
}
