<div id="header">
    <div class="container-fluid">
        <div class="row top-bar">
            <div class="col-12">
                <div class="row">
                    <div class="col-4 col-sm-6 d-none d-xl-block"><i class="fas fa-university"></i> A Part of Hindu Group of Institutions </div>
                    <div class="col-12 col-sm-4 d-sm-block d-xl-none text-uppercase text"><i class="fas fa-university"></i> A Part of Hindu Group of Institutions</div>
                    <!-- <div class="col-12 col-sm-8 col-lg-6">
                        <div class="d-flex justify-content-end align-items-center">
                            <div class=" me-1 pe-1" (click)="LinkUrl('http://odp.page.link/app')"
                                style="cursor: pointer;"><i class="fas fa-sign-in-alt"></i> Student Login</div>
                            <div class="vertical-bar rounded me-1 me-md-2"></div>
                            <div class=" me-1 pe-1 d-none d-sm-block" style="cursor: pointer;"
                                (click)="LinkUrl('http://odp.page.link/app')"><i class="fas fa-sign-in-alt"></i> Parent
                                Login</div>
                            <div class="vertical-bar rounded me-1 me-md-2 d-none d-sm-block"></div>
                            <div class=" me-1 pe-1" (click)="LinkUrl('https://school.odpay.in/auth/SCRH/staff')"
                                style="cursor: pointer;"><i class="fas fa-sign-in-alt"></i> Staff Login</div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div id="showcase">
        <div id="headerSlider" class="carousel slide" data-bs-ride="carousel">
            <!-- <ol class="carousel-indicators">
                <li data-bs-target="#headerSlider" data-bs-slide-to="0" class="active"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="1"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="2"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="3"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="4"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="5"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="6"></li>
                <li data-bs-target="#headerSlider" data-bs-slide-to="7"></li>
            </ol> -->
            <div class="carousel-inner">

                <div class="carousel-item active">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[0]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100 admission" src="/assets/img/showcase/1.jpg" alt="program"
                        [ngStyle]="{visibility:isLoading[0]?'hidden':'visible'}" (load)="hideLoader(0)"
                        (error)="hideLoader(0)">

                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[0]">
                        <h1 class="link-shadow">We &nbsp;Prepare &nbsp;Leaders &nbsp;For &nbsp;Tomorrow</h1>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[1]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/2.jpg" alt="Building"
                        [ngStyle]="{visibility:isLoading[1]?'hidden':'visible'}" (load)="hideLoader(1)"
                        (error)="hideLoader(1)">
                </div>
                <!-- <div class="carousel-item ">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[2]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/3.jpg" alt="program"
                        style="filter: brightness(1); " [ngStyle]="{visibility:isLoading[2]?'hidden':'visible'}"
                        (load)="hideLoader(2)" (error)="hideLoader(2)">
                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[1]">

                        <h1 class="link-shadow">"It &nbsp;always &nbsp;seems &nbsp;impossible &nbsp;until &nbsp;it's
                            &nbsp;done."</h1>
                        <button class="btn btn-warning " data-bs-toggle="modal" data-bs-target="#applyModal">Apply
                            today!</button>
                    </div>
                </div> -->
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[3]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/4.jpg" alt="Building"
                        style="filter: brightness(0.8);" [ngStyle]="{visibility:isLoading[3]?'hidden':'visible'}"
                        (load)="hideLoader(3)" (error)="hideLoader(3)">
                </div>
                <div class="carousel-item">
                    <div class="container-fluid" id="loader" *ngIf="isLoading[4]">
                        <app-loader></app-loader>
                    </div>
                    <img class="d-block w-100" src="/assets/img/showcase/5.jpg" alt="Building"
                        style="filter: brightness(0.7);" [ngStyle]="{visibility:isLoading[4]?'hidden':'visible'}"
                        (load)="hideLoader(4)" (error)="hideLoader(4)">
                    <div class="carousel-caption campus d-none d-md-block animate__animated animate__slideInDown"
                        *ngIf="!isLoading[3]">
                        <button class="btn btn-warning ">#SchoolLife</button>
                        <h1 class="link-shadow">The &nbsp;Future &nbsp;Awaits</h1>
                    </div>
                </div>

            </div>
            <a class="carousel-control-prev" href="#headerSlider" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#headerSlider" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <div class="brand d-flex flex-row justify-content-start align-items-center">
        <img src="/assets/img/logo/1.png" alt="Logo" class="img-fluid rounded">
        <h4 id="group-name" class="d-block animate__animated animate__slideInDown link-shadow">HINDU SENIOR SECONDARY SCHOOL,
            SONEPAT , ESTABLISHED 1990 <br> Affiliation No. 530235</h4>
        <h4 id="group-welcome" class="d-none animate__animated animate__slideInDown link-shadow" style="text-transform: uppercase;">Hindu Group of Institutions - 110 years of Academic Excellence <br>
            (Under the ageis of The Sonpeat Hindu Educational and Charitable Society)</h4>
        <h4 id="group-welcome-2" class="d-none animate__animated animate__slideInDown link-shadow" style="text-transform: uppercase;">An Innovative,
            Progressive and English Medium Co-Educational Sr. Sec School</h4>
    </div>

    <div id="menu-bar">
        <nav class="navbar navbar-expand-xl">
            <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#menu">
                <span class="navbar-toggler-icon" style="color: var(--yellow);"><i class="fas fa-bars"></i></span>

            </button>
            <div class="collapse navbar-collapse" id="menu">
                <ul class="navbar-nav  text-center ">
                    <li class="nav-item" id="home" routerLinkActive="active">
                        <a routerLink="/" class="nav-link link-shadow" data-bs-toggle="collapse" data-bs-target="#menu"><i
                                class="fa-solid fa-house"></i></a>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">About Us</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/overview"> About School</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/chairman"> Chairman's Desk</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/principal"> Principal's Desk</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/staff"> Staff List</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/life"> Life At Hindu Sr. Sec. School</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/about/committee"> School Management Committee(SMC)</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/mandatory-public-disclosure"> Mandatory Public Disclosure</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Academic</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/academic/overview"> Academic Overview</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/academic/courses"> Courses For XI-XII </a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/academic/toddler"> Toddler House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/academic/kindergarten"> Key Areas in Kindergarten</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/academic/learning"> Concept Learning</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Facilities</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/play-ground">Play Ground</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/medical">Medical Facilities</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/smart-class">Smart Class</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/sa-re-ga-ma">Sa Re Ga Ma</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/ncc">N.C.C.</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/house">House System</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/library">Library</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/dance">Dance Studio</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/sports">Sports</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/cafeteria">Cafeteria</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/labs">Labs</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/facility/hostel">Hostel Facilities</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Rules</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/attendance">Attendance / Absents</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/disciplinary">Disciplinary Measures</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/punctuality">Punctuality</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/late-comer">Late Comers</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/damage">Damage to School Property</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/library-rule">Library Rules</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/rules/slc">SLC Format</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Gallery</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        href="https://youtube.com/@hinduseniorsecondary?si=YqPf7zTkQULWAp1X" target="_blank">Links For School YouTube Page</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">KHABARNAMA</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">A Visit to Home Minister House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">A Visit to PM House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">A Visit to President House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">Christmas Celebration</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">Yellow Day Celebration</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/co-curricular">Janamashtami Celebration</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Beyond Books</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/beyond/guidance">Guidance and Counselling</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/beyond/cleanliness">Cleanliness</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/beyond/education">Personal And Social Education</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu"
                                        routerLink="/beyond/workshops">Workshops & Seminars</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link link-shadow" data-bs-toggle="collapse"
                            data-bs-target="#menu">Contact Us</a>
                    </li>

                </ul>
            </div>
        </nav>
    </div>

</div>

<div id="menu-header1">
    <div class="container">
        <nav class="navbar navbar-expand-xl">
            <a class="navbar-brand text-center ml-5" href="#"><img src="/assets/img/logo/1.png" alt="Logo"
                    class="img-fluid"></a>
            <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#menu1">
                <span class="navbar-toggler-icon" style="color: var(--white);"><i class="fas fa-bars"></i></span>

            </button>
            <div class="collapse navbar-collapse" id="menu1">
                <ul class="navbar-nav  text-center ">
                    <li class="nav-item" id="home" routerLinkActive="active">
                        <a routerLink="/" class="nav-link link-shadow" data-bs-toggle="collapse" data-bs-target="#menu1"><i
                                class="fa-solid fa-house"></i></a>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">About Us</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/overview"> About School</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/chairman"> Chairman's Desk</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/principal"> Principal's Desk</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/staff"> Staff List</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/life"> Life At Hindu Sr. Sec. School</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/about/committee"> School Management Committee(SMC)</a>
                                    <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/mandatory-public-disclosure"> Mandatory Public Disclosure</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Academic</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/academic/overview"> Academic Overview</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/academic/courses"> Courses For XI-XII </a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/academic/toddler"> Toddler House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/academic/kindergarten"> Key Areas in Kindergarten</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/academic/learning"> Concept Learning</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Facilities</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/play-ground">Play Ground</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/medical">Medical Facilities</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/smart-class">Smart Class</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/sa-re-ga-ma">Sa Re Ga Ma</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/ncc">N.C.C.</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/house">House System</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/library">Library</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/dance">Dance studio</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/sports">Sports</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/cafeteria">Cafeteria</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/labs">Labs</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/facility/hostel">Hostel Facilities</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Rules</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/attendance">Attendance / Absents</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/disciplinary">Disciplinary Measures</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/punctuality">Punctuality</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/late-comer">Late Comers</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/damage">Damage to School Property</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/library-rule">Library Rules</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/rules/slc">SLC Format</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Gallery</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        href="https://youtube.com/@hinduseniorsecondary?si=YqPf7zTkQULWAp1X" target="_blank">Links For School YouTube Page</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">KHABARNAMA</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">A Visit to Home Minister House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">A Visit to PM House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">A Visit to President House</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">Christmas Celebration</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">Yellow Day Celebration</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/co-curricular">Janamashtami Celebration</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown " id="smallDrop" routerLinkActive="active">
                        <a href="#" class="nav-link dropdown-toggle link-shadow" id="#menu1" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Beyond Books</a>
                        <div class="dropdown-menu " aria-labelledby="menu">
                            <div class="row">
                                <div class="col-12 ">
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/beyond/guidance">Guidance and Counselling</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/beyond/cleanliness">Cleanliness</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/beyond/education">Personal And Social Education</a>
                                    <a class="dropdown-item " data-bs-toggle="collapse" data-bs-target="#menu1"
                                        routerLink="/beyond/workshops">Workshops & Seminars</a>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contact-us" class="nav-link link-shadow" data-bs-toggle="collapse"
                            data-bs-target="#menu1">Contact Us</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>

<a id="whatsapp" class="text-center align-content-around"
    href="https://wa.me/+01302222257?text=Hello%20!%20Need%20more%20information%20for%20admission%20." target="_blank"
    rel="noopener noreferer">
    <i class="fa-brands fa-whatsapp"></i>
</a>

<div id="side-quick-links">
    <ul>
        <li class="call">
            <div class="content">
                <a href="https://x.com/hinducourtroad" target="_blank" class="icons"><i class="fa-brands fa-twitter" aria-hidden="true"></i></a>
                <p><a href="https://x.com/hinducourtroad" target="_blank">Twitter</a></p>
            </div>
        </li>
        <li class="facebook">
            <div class="content">
                <a href="https://www.facebook.com/profile.php?id=100009577780091" target="_blank" class="icons"><i
                        class="fa-brands fa-facebook-f" aria-hidden="true"></i></a>
                <p><a href="https://www.facebook.com/profile.php?id=100009577780091" target="_blank">Facebook</a></p>
            </div>
        </li>
        <li class="insta">
            <div class="content">
                <a href="https://www.instagram.com/hinducourtroad/"
                    target="_blank" class="icons"><i class="fa-brands fa-instagram" aria-hidden="true"></i></a>
                <p><a href="https://www.instagram.com/hinducourtroad/"
                        target="_blank">Instagram</a></p>
            </div>
        </li>
        <li class="youtube">
            <div class="content">
                <a href="https://youtube.com/@hinduseniorsecondary?si=YqPf7zTkQULWAp1X" target="_blank" class="icons"><i
                        class="fa-brands fa-youtube" aria-hidden="true"></i></a>
                <p><a href="https://youtube.com/@hinduseniorsecondary?si=YqPf7zTkQULWAp1X" target="_blank">Youtube</a></p>
            </div>
        </li>
        <li class="contact">
            <div class="content">
                <a href="tel:+0130-2222257" class="icons"><i class="fa fa-phone" aria-hidden="true"></i></a>
                <p><a href="tel:+0130-2222257">Contact Us</a></p>
            </div>
        </li>
    </ul>
</div>

<a class="btn mandatory blink" routerLink="/registration">Admission Open</a>