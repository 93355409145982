import { Component, OnInit } from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  popups: any[] = [
    '/assets/img/popup/1.png'
  ]
  constructor() {
  }
  
  ngOnInit(): void {
    $("#popupModal").modal('show');
  }

}
