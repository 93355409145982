import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './screens/header/header.component';
import { FooterComponent } from './screens/footer/footer.component';
import { HomeComponent } from './screens/home/home.component';
import { AboutUsComponent } from './screens/home/about-us/about-us.component';
import { MessageComponent } from './screens/home/message/message.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InfraComponent } from './screens/home/infra/infra.component';
import { EventsComponent } from './screens/home/events/events.component';
import { CurriculumComponent } from './screens/home/curriculum/curriculum.component';
import { WhyComponent } from './screens/home/why/why.component';
import { BulletinComponent } from './screens/home/bulletin/bulletin.component';
import { LoaderComponent } from './screens/shared/loader/loader.component';
import { MapComponent } from './screens/footer/map/map.component';
import { AboutModule } from './modules/about/about.module';
import { SharedModule } from './modules/shared/shared.module';
import { AcademicModule } from './modules/academic/academic.module';
import { CbseComponent } from './screens/cbse/cbse.component';
import { ContactModule } from './modules/contact/contact.module';
import { RulesModule } from './modules/rules/rules.module';
import { FacilityModule } from './modules/facility/facility.module';
import { BeyondModule } from './modules/beyond/beyond.module';
import { AlumniComponent } from './screens/home/alumni/alumni.component';
import { AchievementModule } from './modules/achievement/achievement.module';
import { RegistrationComponent } from './screens/registration/registration.component';
import { AlumniModule } from './modules/alumni/alumni.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutUsComponent,
    MessageComponent,
    InfraComponent,
    EventsComponent,
    CurriculumComponent,
    WhyComponent,
    BulletinComponent,
    LoaderComponent,
    MapComponent,
    CbseComponent,
    AlumniComponent,
    RegistrationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    SharedModule,
    AboutModule,
    AcademicModule,
    ContactModule,
    FacilityModule,
    RulesModule,
    BeyondModule,
    AchievementModule,
    AlumniModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
