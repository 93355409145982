import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-punctuality',
  templateUrl: './punctuality.component.html',
  styleUrls: ['./punctuality.component.scss']
})
export class PunctualityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
