<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Academic Overview</h4>
                    <div class="card-body ">
                        <p class="inner-lead">For us education and commitment to meaningful education are of prime
                            importance. Quality education is provided to each and every child. Development of oral and
                            written expression is emphasized upon home assignments are not a carry – over of class work
                            but oriented towards honing individual talent.</p>
                        <h6>Accreditation</h6>
                        <p class="inner-lead">The school is affiliated to the Central Board of Secondary Education
                            (CBSE), which is the largest Educational Board in the country. Whilst high academic
                            achievement is a priority, the school aims to create a ‘community’ of well-rounded
                            individuals where a spirit of co-operation and mutual respect exist among the students and
                            teachers. The school has Primary, Middle, Secondary and Senior Secondary classes. There are
                            about 3000 students in the school. The school is dedicated to maintain an environment with
                            respect for the individual and an outstanding teacher to develop independent thinking,
                            creativity and responsibility in every child. We also recognize the importance of parents in
                            the education process of students. We believe that in order to understand a child with the
                            thoroughness that one deserves, there should be regular Parent – Teacher interaction. The
                            school actively promotes links with parents to ensure that all times they are kept fully
                            informed of any concern the school or parents may have.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>