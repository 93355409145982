<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Olympiad</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Our school conducted <b>ORANGE GLOBAL OLYMPIAD</b> in November 2023.Various students participated in each class in maths and science subjects. Many students got medals.</p>

                        <div class="overflow-x-scroll">
                            <table class="table table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="text-center" colspan="3">Medal Winners Student’s List Subject - Science</th>
                                    </tr>
                                    <tr>
                                        <th>Class</th>
                                        <th>Student Name</th>
                                        <th>Medal Type</th>
                                    </tr>
                                    <tr *ngFor="let item of science">
                                        <td>{{ item.class }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.medal }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="overflow-x-scroll mt-4">
                            <table class="table table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="text-center" colspan="3">Medal Winners Student’s List Subject - Mathematics</th>
                                    </tr>
                                    <tr>
                                        <th>Class</th>
                                        <th>Student Name</th>
                                        <th>Medal Type</th>
                                    </tr>
                                    <tr *ngFor="let item of maths">
                                        <td>{{ item.class }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.medal }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>