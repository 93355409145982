<div id="rules">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Attendance / Absents</h4>
                    <div class="card-body ">
                        <ul>
                            <li>
                                <p class="inner-lead">A leave note of absence is desired from parents/guardian, when a
                                    student is absent from the school.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Leave without prior sanction of the Principal may be treated as
                                    absence.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Lack of proper information over a period of two weeks will result
                                    in the deletion of the name from the roll.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Principal has all the right to introduce adequate measures and
                                    steps to prevent massive absence on such occasion and every student is bound to obey
                                    them.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Withdrawal of the child from the school for social gathering is
                                    not encouraged.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Absence on account of illness should be supported with proper
                                    medical and fitness certificates.</p>
                            </li>
                            <li>
                                <p class="inner-lead">No leave of absence will be granted except for genuine reason.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>