<div id="beyond">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Guidance and Counselling</h4>
                    <div class="card-body ">
                        <p class="inner-lead">To facilitate the holistic development of our students without any
                            obstacle of physical disabilities, learning disabilities and behavioural problems on account
                            of exposure to media, the school ensures that our students get regular guidance from a
                            professional. For the same, the school has appointed a counselor who guides our students,
                            teachers and parents. Earnest effort is made by us to help our students in making the most
                            appropriate career choices. Career Counseling Workshops are conducted for the students and
                            parents by a trained professional.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>