import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/showcase/showcase.service';

@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.scss']
})
export class AchievementComponent implements OnInit {
  sub: Subscription;
  constructor(private showcaseService: ShowcaseService,) { }

  ngOnInit(): void {
    this.showcaseService.hideShowcase();
  }

  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }
}
