<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Courses for XI – XII</h4>
                    <div class="card-body ">
                        <p class="inner-lead">We provide all the three streams for Sr. Sec. Classes i.e. Science
                            (Medical & Non – Medical), Commerce and Humanities.</p>
                        <p class="inner-lead">The Subjects taught in these streams include:</p>
                        <p class="inner-lead"><b>Science: </b>Physics, Chemistry, Bio/Mathematics, I.P/Physical
                            Education & English.</p>
                        <p class="inner-lead"><b>Commerce: </b>English, Accountancy, Business Studies, Mathematics / I.P
                            / Physical Education, Hindi / Economics.</p>
                        <p class="inner-lead"><b>Humanities: </b>English, Hindi, History, Political Science, Mathematics
                            / Physical Education.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>