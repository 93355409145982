<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>Achievements</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/academics"><i class="fa fa-angle-right" aria-hidden="true"></i>Academics</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/stars"><i class="fa fa-angle-right" aria-hidden="true"></i>Shining Stars</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/co-curricular"><i class="fa fa-angle-right" aria-hidden="true"></i>Co-Curricular Activities and Competitions</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/olympiad"><i class="fa fa-angle-right" aria-hidden="true"></i>Olympiad</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/interhouse"><i class="fa fa-angle-right" aria-hidden="true"></i>LIST OF PRIZE WINNERS OF INTERHOUSE ACTIVITIES 2023-2024</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/sports"><i class="fa fa-angle-right" aria-hidden="true"></i>SPORTS</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/achievement/state-level"><i class="fa fa-angle-right" aria-hidden="true"></i>LIST  OF STUDENTS SELECTED FOR STATE LEVEL COMPETITION GAMES (2023-24)</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>