import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-olympiad',
  templateUrl: './olympiad.component.html',
  styleUrls: ['./olympiad.component.scss']
})
export class OlympiadComponent implements OnInit {
  science = [
    { class: '3', name: 'ARCHITA', medal: 'Gold Medal' },
    { class: '6', name: 'GARVIT ATTRI', medal: 'Gold Medal' },
    { class: '6', name: 'LAKSHAY BHARDWAJ', medal: 'Silver Medal' },
    { class: '6', name: 'YASH', medal: 'Silver Medal' },
    { class: '8', name: 'ISHAN LAHOT', medal: 'Gold Medal' },
    { class: '10', name: 'SANCHIT TOMAR', medal: 'Gold Medal' },
    { class: '10', name: 'DHRUV DAHIYA', medal: 'Silver Medal' },
    { class: '10', name: 'KUSHAL GAUTAM', medal: 'Bronze Medal' },
  ];

  maths = [
    { class: '3', name: 'VANSHIKA', medal: 'Gold Medal' },
    { class: '4', name: 'AAYUSH KUMAR', medal: 'Gold Medal' },
    { class: '10', name: 'SANCHIT TOAMER', medal: 'Gold Medal' },
    { class: '10', name: 'DHRUV DAHIYA', medal: 'Silver Medal' },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
