import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {
  stars: any[] = [
    {
      imgUrl: '/assets/img/achievement/stars/kapil.jpg',
      title: 'Kapil school topper in class 12th CBSE(2023) with 97.4%'
    },
    {
      imgUrl: '/assets/img/achievement/stars/avni.jpg',
      title: 'Avni got second positionin Bharat Vikas Parishad.'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
