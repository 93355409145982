import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeyondComponent } from 'src/app/screens/beyond/beyond.component';
import { CleanlinessComponent } from 'src/app/screens/beyond/cleanliness/cleanliness.component';
import { EducationComponent } from 'src/app/screens/beyond/education/education.component';
import { GuidanceComponent } from 'src/app/screens/beyond/guidance/guidance.component';
import { WorkshopComponent } from 'src/app/screens/beyond/workshop/workshop.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    BeyondComponent,
    GuidanceComponent,
    CleanlinessComponent,
    EducationComponent,
    WorkshopComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      { path: '', component: BeyondComponent, children: [
        {path:'guidance', component: GuidanceComponent},
        {path:'cleanliness', component: CleanlinessComponent},
        {path:'education', component: EducationComponent},
        {path:'workshops', component: WorkshopComponent},
      ] }
    ])
  ]
})
export class BeyondModule { }
