<div id="message">
    <div class="container">

        <!-- <owl-carousel-o [options]="customOptions">
            <ng-container> -->

        <!-- <ng-template carouselSlide>
                    <div class="row">
                        <div class="col-12 col-md-4 my-auto mb-3 mb-md-0">
                            <img src="/assets/img/message/seth-chhaju-ram.jpg" class="img-fluid rounded ms-0 ms-md-3"
                                alt="Founder Message">
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="card">
                                <div class="card-body">
                                    <h2 class="home-title">Seth Chhaju Ram Ji</h2>
                                    <div class="sub-title mb-4">
                                        <h5 class="mb-0">Our Founder</h5>
                                    </div>
                                    <p>Seth Chhaju Ram Ji (1865-1943) hailed from village Alakhpura in district Bhiwani.
                                        He was concerned about the illiteracy among ruralites. Consequently, he thought
                                        of disseminating education among the countryside people by establishing this
                                        school at an estimated cost of Rs. 4 Lakh, a huge amount in those days. The
                                        school started imparting education upto matriculation right from early thirties.
                                        The credit of upgrading the school into a degree college can be attributed to
                                        three late luminaries. They were founder President, late Ch. Suraj Mal Ji,
                                        General Secretary, Late Justice Davender Lamba and founder Principal, late Sh.
                                        S.S.Gill. Their contribution to the college has been an instance of rare
                                        devotion. Their tireless and continuous efforts were certainly of great help in
                                        meeting the challenges of the formative period of the college. Since its
                                        inception in 1967, the college is deemed as a premier institution of Haryana.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="row">
                        <div class="col-12 col-md-4 my-auto">
                            <img src="/assets/img/message/president.jpg" class="img-fluid rounded ms-3"
                                alt="President Message">
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="card">
                                <div class="card-body">
                                    <h2 class="home-title">Sh. Ajmer Danda</h2>
                                    <div class="sub-title mb-4">
                                        <h5 class="mb-0">President's Message</h5>
                                    </div>
                                    <p>I am glad to witness that our vision is getting embodied with firm and steady
                                        results over the time. It is indeed a matter of delight and pride to see how Jat
                                        Education Society is growing steadily since its inception when a small seed sown
                                        in the soil has grown into a mighty tree of knowledge where thousands of
                                        students are acquiring the nectar of wisdom and knowledge each day.I am proud to
                                        acknowledge many praiseworthy contributions ofthe entire Jat Education Society
                                        team for the cause of all round development of the students through their
                                        incessant efforts round the clock. I am sure, in the years ahead as well, this
                                        Institute will educate and make the students committed and dedicated to the
                                        members of the family, society and the country at large. I extend my best wishes
                                        to the staff, students, parents, well-wishers and every individual directly and
                                        indirectly associated with us for the further prosperity of this Society.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template> -->

        <!-- <ng-template carouselSlide> -->
        <div class="row">
            <div class="col-12 col-md-4 my-auto d-flex justify-content-center">
                <img src="/assets/img/message/principal.jpg" class="img-fluid rounded" alt="Principal Message">
            </div>
            <div class="col-12 col-md-8 mt-4 mt-md-0">
                <div class="card">
                    <div class="card-body">
                        <h2 class="home-title">Ms. Hema Chaturvedi</h2>
                        <div class="sub-title mb-4">
                            <h5 class="mb-0">Principal's Message</h5>
                        </div>
                        <p>Ours is a distinguished institution with a rich legacy of academic excellence, cultural
                            heritage and holistic development.I am deeply honored to head this vibrant community and am
                            committed to building upon the strong foundation that has been laid over the years.My vision
                            for Hindu Sr. Sec. School is to create a dynamic and inclusive learning environment where
                            students can unlock their unique potential and thrive. I firmly believe in fostering a
                            culture of critical thinking, creativity and innovation to prepare our students to excel in
                            a rapidly changing world.Academic rigor is at the core of our efforts, with a focus on
                            implementing innovative teaching methodologies, strengthening curricular offerings and
                            providing
                            personalized learning support to help students achieve their academic aspirations.Equally,I
                            aim to nurture holistic development by promoting a vibrant co-curricular program that
                            encourages active participation in sports, arts and community service.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- </ng-template>

            </ng-container>
        </owl-carousel-o> -->

    </div>
</div>