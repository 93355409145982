<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Chairman's Desk</h4>
                    <div class="card-body ">
                        <div class="text-center">
                            <img src="/assets/img/message/president.jpg" alt="" class="img-fluid rounded-4 h-100">
                        </div>
                        <p class="inner-lead">The Hindu Senior Secondary School has been founded to promote and devote
                            to students’ learning, growth and development; we value diversity with enriched culture of
                            sharing and service, trust and pride.</p>
                        <p class="inner-lead fw-bold">As a part of Hindu Educational and Charitable Society Sonepat, we
                            affirm the following commitments:</p>
                        <ul>
                            <li>
                                <p class="inner-lead">We believe that education is service to society; our mission is to
                                    educate for empowerment and enlightenment.</p>
                            </li>
                            <li>
                                <p class="inner-lead">We shall strive to make our school a place where each child’s
                                    potential is developed to the optimum, so that we may reach the goal of excellence
                                    in a competitive world.</p>
                            </li>
                            <li>
                                <p class="inner-lead">We recognize the critical importance of taking ownership of our
                                    learning. We seek to learn from the full range of our experience, to be open to new
                                    experiences, and new ideas, and to continuously pursue excellence and fulfillment in
                                    our intellectual, social and spiritual pursuits and lifelong learning.</p>
                            </li>
                            <li>
                                <p class="inner-lead">We value differences of opinion and perspective as well as open,
                                    respectful dialogue about those differences, as central to the ongoing learning
                                    process. Every learner is to benefit when ideas, insights and experiences are shared
                                    with others.</p>
                            </li>
                            <li>
                                <p class="inner-lead">People working and living together for the common goal is the key
                                    to the growth both for the individual and the community. We commit ourselves to
                                    participate in community services and volunteer activities, both in and outside the
                                    campus.</p>
                            </li>
                            <li>
                                <p class="inner-lead">Enhancing environmental quality is critical to the school, the
                                    community and ultimately to the survival of the planet. We act to maintain and
                                    improve our facilities and grounds to enhance the safety, security and the
                                    appearance of our surroundings, and to protect the ecology in all five elements –
                                    Earth, Sky, Air, Energy and Water, for the larger community.</p>
                            </li>
                        </ul>
                        <p class="inner-lead fw-bold">The Sonipat Hindu Educational and Charitable Society</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>