<div id="alumni" class="py-5">
    <div class="container">
        <owl-carousel-o [options]="customOptions">

            <ng-container *ngFor="let item of alumni; let i=index">
                <ng-template carouselSlide>
                    <div class="row">
                        <div class="col-3">
                            <figure class="figure">
                                <!-- <img [src]="item.imgPath" [alt]="item.name" class="img-fluid"> -->
                                <!-- <video
                                    class="img-fluid"
                                    [src]="item.videoPath"
                                    controls>
                                </video> -->
                                <video width="100%" height="360" controls>
                                    <source [src]="item.videoPath" type="video/mp4">
                                    <!-- Your browser does not support the video tag. -->
                                  </video>
                            </figure>
                        </div>
                        <div class="col-9">
                            <div class="alumniContent ps-5">
                                <h2 class="home-title">Alumni <br>Speaks</h2>
                                <blockquote>{{item.description}}</blockquote>
                                <h6>{{item.name}}</h6>
                                <p>{{item.designation}}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>