<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>About Us</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/about/overview"><i class="fa fa-angle-right" aria-hidden="true"></i>About School</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/vision"><i class="fa fa-angle-right" aria-hidden="true"></i>Vision & Values</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/chairman"><i class="fa fa-angle-right" aria-hidden="true"></i>Chairman's Desk</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/principal"><i class="fa fa-angle-right" aria-hidden="true"></i>Principal's Desk</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/staff"><i class="fa fa-angle-right" aria-hidden="true"></i>Staff List</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/life"><i class="fa fa-angle-right" aria-hidden="true"></i>Life At Hindu Sr. Sec. School</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/about/committee"><i class="fa fa-angle-right" aria-hidden="true"></i>School Management Committee(SMC)</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/mandatory-public-disclosure"><i class="fa fa-angle-right" aria-hidden="true"></i>Mandatory Public Disclosure</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>