<div id="beyond">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Workshops & Seminars </h4>
                    <div class="card-body ">
                        <div class="row text-center">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/workshop/1.jpg" alt="" class="img-fluid rounded-4" style="object-position: top;"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/workshop/2.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/workshop/3.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/workshop/4.jpg" alt="" class="img-fluid rounded-4"></div>
                        </div>
                        <p class="inner-lead">Workshops and Seminars are the integral parts
                            of our institution. They are conducted time to time for the teachers as well as
                            students to keep them abreast of latest information in their fields. Orientation
                            Programmes and Career Counselling Sessions are also organized for guiding the
                            students.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>