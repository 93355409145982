<div id="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-lg-3">
                <div class="widget dark">
                    <div class="footer-logo">
                        <img class="mt-3 img-fluid rounded-circle mb-3" alt="Logo" src="/assets/img/logo/1.png">
                    </div>
                  
                  <h4 class=" text-center mb-0">HINDU SENIOR SECONDARY SCHOOL</h4>
                  <p class="text-center">New Court Road, Sonepat – 131001, Haryana</p>
                  <ul class="list-inline  text-center">
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-phone me-2"></i> <a  href="tel:0130-2222257">0130-2222257</a> </li>
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-phone me-2"></i> <a  href="tel:0130-2221444">0130-2221444</a> </li>
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-envelope  me-2"></i> <a class="spamspan" href="mailto:hinducourtroad@gmail.com">hinducourtroad@gmail.com</a> </li>
                    <li class="m-0 ps-10 pe-10"> <i class="fa-solid fa-link me-2"></i> <a  href="https://hssschool.org/" target="_blank">www.hssschool.org</a> </li>
                  </ul>
                  <ul class="social clearfix mt-10 text-center">
                    <li><a href="https://x.com/hinducourtroad" target="_blank" aria-label="Twitter"><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href="https://www.instagram.com/hinducourtroad/" target="_blank" aria-label="Instagram"><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a href="https://www.facebook.com/profile.php?id=100009577780091" target="_blank" aria-label="Facebook"><i class="fa-brands fa-facebook-f"></i></a></li> 
                    <li><a href="https://youtube.com/@hinduseniorsecondary?si=YqPf7zTkQULWAp1X" target="_blank" aria-label="Youtube"><i class="fa-brands fa-youtube"></i></a></li>
                            
                  </ul>
                </div>
              </div>
            <div class="col-md-6 col-lg-3">
                <div class="link">
                    <h4 class="">Quick Links</h4>
                    <hr class="mb-0  opacity-100">
                    <ul class="ps-0">
                        <li><a href="https://www.cbse.gov.in" target="_blank" >CBSE Website</a></li>
                        <li><a routerLink="/admission-process">Admission Process</a></li>
                        <li><a routerLink="/alumni" >Alumni</a></li>
                        <li><a routerLink="/mandatory-public-disclosure" >Mandatory Disclosure</a></li>
                        <li><a routerLink="/contact-us" >Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
                <div class="link borderRight">
                    <h4 class="">Explore</h4>
                    <hr class="mb-0  opacity-100">
                    <ul class="ps-0">
                        <li><a href="/assets/pdf/result XII.pdf" target="_blank">Results Class XII</a></li>
                        <li><a href="/assets/pdf/result X.pdf" target="_blank">Results Class X</a></li>
                        <li><a href="/assets/pdf/examCalender.pdf" target="_blank">Examination Calender</a></li>
                        <li><a href="/assets/pdf/fees.pdf" target="_blank">Fee Structure</a></li>
                        <li><a routerLink="/gallery">Gallery</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
               <app-map></app-map>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-right">
                <a href="https://okiedokiepay.com/" class="design" target="_blank">Crafted with ❤️ by Okie Dokie <br>
                    Haryana's No. 1 Campus Automation Partner</a>
            </div>
        </div>
    </div>
</div>