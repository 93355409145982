import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CafeteriaComponent } from 'src/app/screens/facility/cafeteria/cafeteria.component';
import { ClassComponent } from 'src/app/screens/facility/class/class.component';
import { DanceComponent } from 'src/app/screens/facility/dance/dance.component';
import { FacilityComponent } from 'src/app/screens/facility/facility.component';
import { GroundComponent } from 'src/app/screens/facility/ground/ground.component';
import { HostelComponent } from 'src/app/screens/facility/hostel/hostel.component';
import { HouseComponent } from 'src/app/screens/facility/house/house.component';
import { LabComponent } from 'src/app/screens/facility/lab/lab.component';
import { LibraryComponent } from 'src/app/screens/facility/library/library.component';
import { MedicalComponent } from 'src/app/screens/facility/medical/medical.component';
import { NccComponent } from 'src/app/screens/facility/ncc/ncc.component';
import { SaregamaComponent } from 'src/app/screens/facility/saregama/saregama.component';
import { SportsComponent } from 'src/app/screens/facility/sports/sports.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    FacilityComponent,
    GroundComponent,
    MedicalComponent,
    ClassComponent,
    NccComponent,
    HouseComponent,
    LibraryComponent,
    DanceComponent,
    SportsComponent,
    CafeteriaComponent,
    LabComponent,
    HostelComponent,
    SaregamaComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: FacilityComponent, children: [
          { path: 'play-ground', component: GroundComponent },
          { path: 'medical', component: MedicalComponent },
          { path: 'smart-class', component: ClassComponent },
          { path: 'ncc', component: NccComponent },
          { path: 'house', component: HouseComponent },
          { path: 'library', component: LibraryComponent },
          { path: 'dance', component: DanceComponent },
          { path: 'sports', component: SportsComponent },
          { path: 'cafeteria', component: CafeteriaComponent },
          { path: 'labs', component: LabComponent },
          { path: 'hostel', component: HostelComponent },
          { path: 'sa-re-ga-ma', component: SaregamaComponent },
        ]
      }
    ])
  ]
})
export class FacilityModule { }
