<div id="academic">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Kindergarten</h4>
                    <div class="card-body ">
                        <h6>Key Areas in Kindergarten Curriculum :</h6>
                        <ul>
                            <li>
                                <p class="inner-lead">Personal Social and Emotional Development</p>
                            </li>
                            <li>
                                <p class="inner-lead">Cognitive development</p>
                            </li>
                            <li>
                                <p class="inner-lead">Theme based Concept Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Math readiness</p>
                            </li>
                            <li>
                                <p class="inner-lead">Language and Phonics</p>
                            </li>
                            <li>
                                <p class="inner-lead">Inculcating Life-long learning skills</p>
                            </li>
                            <li>
                                <p class="inner-lead">Opportunities for development of aesthetic sense</p>
                            </li>
                        </ul>

                        <h6>Activities Involved</h6>
                        <ul>
                            <li>
                                <p class="inner-lead">Sensory Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Story Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Water Play</p>
                            </li>
                            <li>
                                <p class="inner-lead">Sand Play</p>
                            </li>
                            <li>
                                <p class="inner-lead">Science Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Creative Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Library Time</p>
                            </li>
                            <li>
                                <p class="inner-lead">Celebration of festivals and special days</p>
                            </li>
                            <li>
                                <p class="inner-lead">Field trips</p>
                            </li>
                            <li>
                                <p class="inner-lead">Puppet shows and skits</p>
                            </li>
                            <li>
                                <p class="inner-lead">Public speaking</p>
                            </li>
                            <li>
                                <p class="inner-lead">Free play with Technology Corner</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>