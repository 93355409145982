<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Shining Stars</h4>
                    <div class="card-body ">
                        <div class="cloud">
                            <div class="row">
                                <div class="col-12 mx-auto  w-50">
                                    <div class="card cloud-card">
                                        <div class="card-body d-flex align-items-center">
                                            <h6>Sanchit Tomar ,School topper and Olympiad topper (two Gold Medals) in
                                                session 2023-24.</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="row d-flex justify-content-around mt-4">
                            <div class="col-md-12 col-xl-6 d-flex align-items-stretch" *ngFor="let item of stars">
                                <div class="card1 mx-md-2 mx-lg-0">
                                    <div class="card-img text-center w-100">
                                        <a [href]="item.imgUrl" data-lightbox="item.caption"> <img [src]="item.imgUrl"
                                                class="img-fluid" alt="A+ Grade"></a>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title"> {{item.title}} </h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>