import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports',
  templateUrl: './sports.component.html',
  styleUrls: ['./sports.component.scss'],
})
export class SportsComponent implements OnInit {
  sports: any[] = [
    {
      title: 'On 8 April 2023 and 9 April 2023, St. Sofia senior secondary school New Delhi organise under- 14 Boys tennis championship,Aryan of 9th B secured second position.',
      img: '/assets/img/achievement/sports/1.jpg',
    },
    {
      title: 'On 21st to 23 April 2023, Haryana state long tennis championship was held, Aryan Chopra of 9th b secured second position and cash prize of rupees 1500/-, Hiten Malik of 12th secured 3rd position. Congratulations to all the winners. ',
      img: '/assets/img/achievement/sports/2.jpg',
    },
    {
      title: 'On 25th to 27 April 202, 14th Haryana state Taekwondo championship held in Ambala.It is pleasure announce that Gaurav of 11 F and Priyanshu of 12 F won bronze medal and selected for Federation Cup.',
      img: '',
    },
    {
      title: 'On 29th to 30 April 2023, Under -19 Basketball open tournament held in Faridabad. Our school team secured second position. Harsh 11 E, Ankit 12 F, Varun 12 E, Saurav 10-B, Ansh.  11 -B',
      img: '/assets/img/achievement/sports/3.jpg',
    },
    {
      title: 'On 9th and 10th June 2023 ,First sub junior North zone roll on championship 2023 held in Varanasi. In this championship two players participated from our school and secured second position. Shreya-6 C   Silver medal , Shruti -6 A    Silver medal ',
      img: '/assets/img/achievement/sports/4.jpg',
    },
    {
      title: 'On 10th September 2023,under- 17 Basketball zone tournament was held in greater Noida,UP.In this event our school team has participated and secured first position and a cash prize of rupees 7000/-. Congratulation to all the team winners. Ankit 12 F, Varun 12 F, Vanshik 12 F, Aryan   11 D',
      img: '/assets/img/achievement/sports/5.jpg',
      img2: '/assets/img/achievement/sports/6.jpg'
    },
    {
      title: 'On 7 September 2023 ,Jai Nirmal sports federation in sector 23 organise Skating competition. Student of our school Ayush Verma 9th B Shruti Verma 6 A Shreya Verma 6 C won gold medal.',
      img: '/assets/img/achievement/sports/7.jpg',
    },
    {
      title: 'On 15 to 17 September 202. 56th Haryana state school Taekwondo championship was held in Rishikul Vidyapeeth Sonipat. Komal of 7th B won gold medal and cash price of rupees 1000/- and got selected for National game.',
      img: '/assets/img/achievement/sports/8.jpg',
    },
    {
      title: 'On 18th October to 21st October 2023,BSE North Zone -II Skates Competition held at Mohali Punjab. In this competition Shruti Verma won bronze medal in 300m race and Puneet Sangwan of class 3 rd won bronze medal in 300 m race. ',
      img: '',
    },
    {
      title: 'Shruti Verma got selected for National games.',
      img: '',
    },
    {
      title: 'On 24th October 2023 CBSE North zone second Badminton championship was held NSD Vidya Niketan school Ambala. In this competition Aanchal from 12 F , Bhumika from 11th B and Mehak from 9th D won bronze medal and these students are selected for National games. ',
      img: '',
    },
    {
      title: 'From 26 to 31 October 2023 CBSE North zone second Taekwondo championship was held in Patiala, Punjab. In this competition Parikshit Khatri from 11th A won gold medal and selected for National games.',
      img: '',
    },
    {
      title: 'From 2nd November 24th November 2023 Haryana school state long tennis championship was held in Panchkula. Aryan Chopra of class 9th B got runner up trophy.',
      img: '',
    },
    {
      title: 'From 9 to 13 December 2023, CBSE National Badminton championship was held at Jhunjhunu Rajasthan. Aanchal of class 12th G secured second position.',
      img: '',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
