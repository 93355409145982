import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Contact } from 'src/app/models/contact.model';
import { ShowcaseService } from 'src/app/services/showcase/showcase.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  showAlert = false;
  showDanger = false;
  constructor(
    private showcaseService: ShowcaseService,
    // private contactService:ContactService
  ) {
  }

  ngOnInit(): void {
    this.showcaseService.hideShowcase();
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    var numbers = /^[0-9]+$/;

    let name = form.value.name;
    let phone = form.value.phone;
    let email = form.value.email;
    let message = form.value.message;
    let date = new Date();
    let status = "new";
    let id = this.idGenrator();
    if (phone.match(numbers)) {
      const contact: Contact = new Contact(id, name, phone, message, email, status, date);
      // this.contactService.setContact(contact);
      form.reset();
    }
    else {
      alert('Please input numeric characters only in phone field!');
      form.reset();
    }

  }
  idGenrator() {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
  };
  ngOnDestroy() {
    this.showcaseService.showingShowcase();
  }
}
