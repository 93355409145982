<div id="beyond">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Cleanliness</h4>
                    <div class="card-body ">
                        <p class="inner-lead">We hold profound belief in the adage, “Cleanliness is next to godliness”.
                            Extra care is taken by us to ensure cleanliness in and outside the school campus.
                            Cleanliness of the building is exemplary. The washrooms are checked, for cleanliness, by our
                            faculty after every hour. The teacher Floor In-charge gives a regular report about
                            cleanliness and maintenance of the classrooms and corridors.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>