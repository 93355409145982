<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Smart Class</h4>
                    <div class="card-body ">
                        <p class="inner-lead fw-bold">Learning becomes effective when the sensory organs receive
                            practical experience.</p>
                        <p class="inner-lead">Our Audio Visual Smart classroom is equipped to give practical (audio and
                            visual) experience to the students. Hindu Senior Secondary School offers futuristic
                            facilities where Smart Class technology is used, to the maximum advantage. Each Smart class
                            is provided with a Digital board, a Computer and LCD projector.</p>
                        <p class="inner-lead">This is a networked, wired classroom where the best Audio-Visual learning
                            practice, is offered to the students. Interactive learning is promoted through these ICT
                            Smart boards that make education, an exhilarating experience for the students.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>