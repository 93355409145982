import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {
  event: any[] = [
    {
      title: 'Pre-Primary School',
      imgUrl: '/assets/img/curriculum/kindergarten.jpeg'
    },
    {
      title: 'Junior School',
      imgUrl: '/assets/img/curriculum/junior.jpeg'
    },
    {
      title: 'Senior School',
      imgUrl: '/assets/img/curriculum/senior.jpeg'
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
