import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-state-level',
  templateUrl: './state-level.component.html',
  styleUrls: ['./state-level.component.scss']
})
export class StateLevelComponent implements OnInit {
  players = [
    { name: "Shivam", class: 11, game: "Handball", districtPosition: "---", statePosition: "Third" },
    { name: "Vinay", class: 10, game: "Handball", districtPosition: "Third", statePosition: "---" },
    { name: "Aryan", class: 11, game: "Handball", districtPosition: "Third", statePosition: "---" },
    { name: "Gaurav", class: 11, game: "Taekwando", districtPosition: "First", statePosition: "Second" },
    { name: "Parikshit Khatri", class: 9, game: "Taekwando", districtPosition: "Second", statePosition: "---" },
    { name: "Ankush", class: 9, game: "Wrestling", districtPosition: "First", statePosition: "---" },
    { name: "Hemant Kumar", class: 10, game: "Wrestling", districtPosition: "Second", statePosition: "---" },
    { name: "Deepanshu", class: 11, game: "Wrestling", districtPosition: "Second", statePosition: "---" },
    { name: "Anshu Kundu", class: 11, game: "Judo", districtPosition: "Second", statePosition: "---" },
    { name: "Takshak Kumar", class: 11, game: "Badminton", districtPosition: "---", statePosition: "Third" },
    { name: "Nitesh", class: 12, game: "Badminton", districtPosition: "---", statePosition: "---" },
    { name: "Kunal", class: 12, game: "Badminton", districtPosition: "---", statePosition: "---" },
    { name: "Aanchal", class: 12, game: "Badminton", districtPosition: "Second", statePosition: "Third" },
    { name: "Bhumika", class: 11, game: "Badminton", districtPosition: "Second", statePosition: "Third" },
    { name: "Mehak", class: 9, game: "Badminton", districtPosition: "Second", statePosition: "---" },
    { name: "Anushka", class: 9, game: "Badminton", districtPosition: "Second", statePosition: "---" },
    { name: "Ansh", class: 9, game: "Basketball", districtPosition: "First", statePosition: "---" },
    { name: "Prateek", class: 10, game: "Basketball", districtPosition: "First", statePosition: "---" },
    { name: "Nitashika", class: 12, game: "Basketball", districtPosition: "First", statePosition: "First" },
    { name: "Rashi", class: 11, game: "Basketball", districtPosition: "First", statePosition: "First" },
    { name: "Ronak", class: 9, game: "Hockey", districtPosition: "First", statePosition: "---" },
    { name: "Disha", class: 9, game: "Karate", districtPosition: "First", statePosition: "---" }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
