<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">House System</h4>
                    <div class="card-body ">
                        <div class="row text-center">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/house/1.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/house/2.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/house/3.jpg" alt="" class="img-fluid rounded-4"></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/facility/house/4.jpg" alt="" class="img-fluid rounded-4"></div>
                        </div>
                        <p class="inner-lead">For inculcating and developing the qualities of leadership, public speaking and
                            organizational skills among the students, the school has been divided into four
                            houses i.e. Azad, Patel, Subhash and Tilak. Points are awarded to the houses
                            throughout the year by conducting Inter House Co-Curricular Activities, Sports
                            Tournaments, Debates, Quizzes etc. At the end of the academic session running
                            trophy is awarded to the house gaining the highest points.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>