import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AboutComponent } from 'src/app/screens/about/about.component';
import { ChairmanComponent } from 'src/app/screens/about/chairman/chairman.component';
import { CommitteeComponent } from 'src/app/screens/about/committee/committee.component';
import { LifeComponent } from 'src/app/screens/about/life/life.component';
import { OverviewComponent } from 'src/app/screens/about/overview/overview.component';
import { PrincipalComponent } from 'src/app/screens/about/principal/principal.component';
import { StaffComponent } from 'src/app/screens/about/staff/staff.component';
import { VisionComponent } from 'src/app/screens/about/vision/vision.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    AboutComponent,
    OverviewComponent,
    ChairmanComponent,
    PrincipalComponent,
    StaffComponent,
    CommitteeComponent,
    LifeComponent,
    VisionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PdfViewerModule,
    RouterModule.forChild([
      {path:'', component: AboutComponent, children:[
        {path:'overview', component: OverviewComponent},
        {path:'chairman', component: ChairmanComponent},
        {path:'principal', component: PrincipalComponent},
        {path:'staff', component: StaffComponent},
        {path:'committee', component: CommitteeComponent},
        {path:'life', component: LifeComponent},
        {path:'vision', component: VisionComponent},
      ]}
    ])
  ]
})
export class AboutModule { }
