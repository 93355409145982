import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/showcase/showcase.service';

@Component({
  selector: 'app-beyond',
  templateUrl: './beyond.component.html',
  styleUrls: ['./beyond.component.scss']
})
export class BeyondComponent implements OnInit {
  sub:Subscription;
  constructor(private showcaseService:ShowcaseService,) { }

  ngOnInit(): void {
    this.showcaseService.hideShowcase();
  }

  ngOnDestroy(){
    this.showcaseService.showingShowcase();
  }
}
