import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibraryComponent } from 'src/app/screens/rules/library/library.component';
import { AttendanceComponent } from 'src/app/screens/rules/attendance/attendance.component';
import { DisciplineComponent } from 'src/app/screens/rules/discipline/discipline.component';
import { LateComponent } from 'src/app/screens/rules/late/late.component';
import { PropertyComponent } from 'src/app/screens/rules/property/property.component';
import { PunctualityComponent } from 'src/app/screens/rules/punctuality/punctuality.component';
import { RulesComponent } from 'src/app/screens/rules/rules.component';
import { SlcComponent } from 'src/app/screens/rules/slc/slc.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [
    RulesComponent,
    AttendanceComponent,
    DisciplineComponent,
    PunctualityComponent,
    LateComponent,
    PropertyComponent,
    LibraryComponent,
    SlcComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([
      {
        path: '', component: RulesComponent, children: [
          { path: 'attendance', component: AttendanceComponent },
          { path: 'disciplinary', component: DisciplineComponent },
          { path: 'punctuality', component: PunctualityComponent },
          { path: 'late-comer', component: LateComponent },
          { path: 'damage', component: PropertyComponent },
          { path: 'library-rule', component: LibraryComponent },
          { path: 'slc', component: SlcComponent },
        ]
      }
    ])
  ]
})
export class RulesModule { }
