<div id="why" class="py-5">
    <div class="container">
        <div class="row mb-4">
            <div class="col-12 text-center">
                <h2 class="home-title sub-title"><span>Why Choose HSSS</span></h2>
                <h2 class="home-title">Benefits of Joining HSSS</h2>
            </div>
        </div>

        <div class="row justify-content-md-end">
            <ul>
                <li>
                    <div class="icon icon1">
                        <i class="fa-solid fa-chalkboard-user"></i>
                    </div>
                    <div class="detail">
                        <h6>Leader in Education</h6>
                        <p>Providing exceptional, holistic education to shape young minds.</p>
                    </div>
                </li>
                <li>
                    <div class="icon icon2">
                        <i class="fa-solid fa-user-graduate"></i>
                    </div>
                    <div class="detail">
                        <h6>Experienced Faculty</h6>
                        <p>Learn from experienced educators dedicated to student success.
                        </p>
                    </div>
                </li>
                <li routerLink="/achievement">
                    <div class="icon icon3">
                        <i class="fa-solid fa-trophy"></i>
                    </div>
                    <div class="detail">
                        <h6>Achievements & Recognition</h6>
                        <p>Students excel in academics, sports, and cultural activities.
                        </p>
                    </div>
                </li>
            </ul>

            <ul>
                <li>
                    <div class="icon icon4">
                        <i class="fa-solid fa-history"></i>
                    </div>
                    <div class="detail">
                        <h6>Decades of Excellence</h6>
                        <p>Building a legacy of quality education for years.</p>
                    </div>
                </li>
                <li routerLink="/alumni">
                    <div class="icon icon5">
                        <i class="fa-solid fa-users"></i>
                    </div>
                    <div class="detail">
                        <h6>Outstanding Alumni Success</h6>
                        <p>Our alumni shine in diverse fields, carrying our values globally.</p>
                    </div>
                </li>
                <li>
                    <div class="icon icon6">
                        <i class="fa-solid fa-globe"></i>
                    </div>
                    <div class="detail">
                        <h6>Excellence in Education</h6>
                        <p>A globally relevant curriculum blending values with innovation.</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>