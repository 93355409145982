<div id="events" class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h1 class="home-title mb-4">Upcoming Events</h1>
            </div>
        </div>

        <div class="row">
            <owl-carousel-o [options]="customOptions">

                <ng-container *ngFor="let item of event">
                    <ng-template carouselSlide>
                        <div class="card">
                            <div class="image-container"><img [src]="item.imgUrl" class="img-fluid w-100"
                                    [alt]="item.title"></div>
                            <div class="card-body overlay">
                                <h3><strong></strong> <br></h3>
                                <p>{{item.title}}</p>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>