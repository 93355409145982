<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Cafeteria</h4>
                    <div class="card-body ">
                        <p class="inner-lead">A few moments that you snatch for yourself from the hectic schedule are
                            best spent in a convenient layout of the school cafeteria. It provides wholesome snacks and
                            drinks in the most hygienic conditions. It offers a variety of nutritive food items.</p>
                        <p class="inner-lead">Health and nutrition of every student is of prime concern at Hindu Sr.
                            Sec. School. Our endeavour is to provide students with a wide variety of food, while
                            ensuring that they receive a nutritious and balanced diet. The menu has been drafted after
                            taking into account the wishes of students and faculty. The judiciously chosen diet which
                            includes the various items ensures nutrition while allowing students to enjoy a wide and
                            varied menu throughout the year. It not only caters to the needs of students and staff
                            members but also the parents and visitors.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>