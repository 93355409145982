import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slc',
  templateUrl: './slc.component.html',
  styleUrls: ['./slc.component.scss']
})
export class SlcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
