<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>Beyond Books</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/beyond/guidance"><i class="fa fa-angle-right" aria-hidden="true"></i>Guidance and Counselling</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/beyond/cleanliness"><i class="fa fa-angle-right" aria-hidden="true"></i>Cleanliness</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/beyond/education"><i class="fa fa-angle-right" aria-hidden="true"></i>Personal And Social Education</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/beyond/workshops"><i class="fa fa-angle-right" aria-hidden="true"></i>Workshops & Seminars</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>