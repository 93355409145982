<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">About School</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Hindu Senior Secondary School, New Court Road, Sonepat
                            is a highly progressive, English medium, Co-educational school, affiliated to Central Board
                            of
                            Secondary Education, New Delhi. It was established in 1990 with a view to instill
                            moral and spiritual values in the children. In order to fulfil the dream of our founder
                            into reality the school authorities make healthy plans right from the beginning of
                            the academic session to cover various aspects of a student's life.</p>
                        <p class="inner-lead">The school is administered by The Sonepat Hindu Educational and
                            Charitable Society which is non-profitable organization, established in the year 1914 with
                            the
                            aim to implement quality education in Sonepat and surrounding areas. It is one of the
                            oldest and
                            highly reputed educational societies in Northern India. At present the society is running 16
                            educational institutions having their own campus with nearly 22,000 students and one
                            Charitable Hospital.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>