<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Dance studio</h4>
                    <div class="card-body ">
                        <p class="inner-lead">A dance studio is a space in which students learn or rehearse rhythmic
                            movements. The term is typically used to describe a space that has either been built or
                            equipped for the purpose. Dance studio at Hindu Senior Secondary School, New Court Road
                            consists of a smooth hard Rubber floor, covering such floor provides a degree of flexibility
                            to absorb the impact of intensive dance exercise, such as jumping. This is considered vital
                            to promote good health and safety.</p>
                        <p class="inner-lead">Other common features of our dance studio include as music is an integral
                            part of dance, our studios have a sound system for playing CD’s or cassettes tapes, One wall
                            is covered by floor to ceiling mirrors, which are used by dancers to see their body position
                            and alignment. An LED is placed in the dance studio to give a feed back to the students
                            about their own performance and helps them to make them better. The colorful lights give the
                            glimpse of real stage situation and helps children to be more confident at the time of
                            performance.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>