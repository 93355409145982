import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-academics',
  templateUrl: './academics.component.html',
  styleUrls: ['./academics.component.scss']
})
export class AcademicsComponent implements OnInit {
  achievement: { imgUrl: string, name: string, percentage: string, class: string, position: string }[] = [
    {
      imgUrl: '/assets/img/achievement/academic/niharika.jpg',
      name: 'Niharika',
      percentage: '96%',
      class: '12th',
      position: 'School Topper'
    },
    {
      imgUrl: '/assets/img/achievement/academic/kushal.jpg',
      name: 'KUSHAL',
      percentage: '97%',
      class: '10th',
      position: 'School Topper'
    },
    {
      imgUrl: '/assets/img/achievement/academic/sanchit.jpg',
      name: 'Sanchit',
      percentage: '96%',
      class: '10th',
      position: '2nd'
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
