<app-banner></app-banner>

<div class="container-fluid innerPageMenu">
    <div class="row">
        <div class="col-md-3">
            <nav id="secondary-navigation">
                <!-- Header -->
                <header class="header">
                    <h5>Alumni</h5>
                </header>
                <!-- /Header -->
                <ul>
                    <li routerLinkActive="active">
                        <a routerLink="/alumni"><i class="fa fa-angle-right" aria-hidden="true"></i>Alumni</a>
                    </li>
                </ul>
            </nav>
        </div>
        <div class="col-md-9">
            <div id="alumni">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card shadow-lg inner-card">
                                <h4 class="card-title text-center">Alumni</h4>
                                <div class="card-body ">
                                    <h6>Tamanna</h6>
                                    <p class="inner-lead">My name is Tamanna. I have recently passed 12th class from
                                        Hindu Sr. Sec. School which offers a lot of facility to students and well
                                        experienced teachers help a lot to students not only in academics but also in
                                        co-curricular activities. <br>
                                        Thanks.</p>
                                    <h6>Nishtha</h6>
                                    <p class="inner-lead">Hello, I am Nishtha, one of the lucky students who got a
                                        chance to study in Hindu Sr. Sec. School. Our school excels in fostering
                                        academic excellence and personal growth. Along with it, the main essence of our
                                        school is the dedicated, innovative and hardworking staff who dedicates
                                        themselves completely for future of students and consider them as their own
                                        children. Fabulous and well maintained labs with all necessary facilities are
                                        always available. This is a what, a great school all about. A great thank you to
                                        all the teachers for their unwavering commitment to education and
                                        excellence.<br>
                                        Thanks.</p>
                                    <h6>Arpit Pandey</h6>
                                    <p class="inner-lead">Feeling proud to say that I have completed my schooling from
                                        HSSS. I admire most support from everyone at every step. My teachers help me a
                                        lot in brightening my future. I am thankful to HSSS because it gave me a lot of
                                        opportunities to develop my overall personality and play my part in the
                                        betterment of society. The teachers are very learned and experienced in their
                                        subjects and I was very lucky to get into their vicinity. They not only taught
                                        us the subjects but they taught us the philosophy of life which we had to face
                                        in the world.<br>
                                        Thanks.</p>
                                    <h6>Harsh</h6>
                                    <p class="inner-lead">Hi everyone, My name is Harsh. I am a basketball player. My
                                        school always appreciates and motivates me to upgrade my game which helps me to
                                        be the part of Nationals.<br>
                                        Thanks.</p>
                                    <h6>Nancy</h6>
                                    <p class="inner-lead">I am Nancy. I take immense pride in saying that i am pass out
                                        student from Hindu Sr. Sec. School. My academic journey began from here in 2013.
                                        When I joined in 2nd standard and I can confidently say that when I bid farewell
                                        in 2024. I left with a treasure trove of lessons, memories and a deep sense of
                                        satisfaction for the years well spent. The school environment was open and
                                        positive, providing me with numerous opportunities to enhance my personality. I
                                        had privilege of serving as the Head Girl and in various co-curricular
                                        activities, which shaped me into the person I am today. I extend my heartfelt
                                        thanks to HSSS and the worthy teachers for everything.<br>
                                        Thanks.</p>
                                    <h6>Niharika Gautam</h6>
                                    <p class="inner-lead">I was student of Hindu Sr. Sec. School for 12 years (1st to
                                        12th ). I like the school for many reasons. School organizes many events like
                                        inter school quizzes, competitions, debates and many inter house activities
                                        also. Sports and cultural activities has also become quite good in school. There
                                        were workshops on topics like ROBOTICS in ATL lab. School has very experienced
                                        teachers to impart great knowledge. I had great respect towards the idol of
                                        goddess Saraswati, seen as you enter the main gate. The greenery in school
                                        provides a serene environment. Along with my parents, I am thankful to my
                                        teachers and school for making a good person out of me. <br>
                                        Thanks.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>