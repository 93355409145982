<div id="facility">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Library</h4>
                    <div class="card-body ">
                        <p class="inner-lead">The school houses a huge Library space with Senior and Junior Sections,
                            imaginatively stocked with about 16000 titles that cover fiction and non- fiction in all
                            genres and subjects. It also has a stock of multimedia materials (CDs, DVDs and videos) for
                            use in the classes.</p>
                        <p class="inner-lead">In fact, the library specializes in the reference section where the
                            students are actively encouraged to use the library for independent study and for research
                            towards their presentations and projects.</p>
                        <p class="inner-lead">Library stocks are routinely updated with latest addition of the books
                            available in the market.</p>
                        <p class="inner-lead">The membership is open to all students and faculty members, who are issued
                            a library card for all transactions in the library. The school library caters to the need of
                            all classes from Nursery to XII.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>