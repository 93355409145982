<div id="achievement">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Co-Curricular Activities and Competitions</h4>
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/achievement/co-curricular/1.jpg" class="img-fluid rounded" alt=""></div>
                            <div class="col-12 col-md-6 mb-3"><img src="/assets/img/achievement/co-curricular/3.jpg" class="img-fluid rounded" alt=""></div>
                        </div>
                        <p class="inner-lead">Every year Bal Bhawan organized many competitions on the occasion of
                            Children ‘s Day among schools of various states of India and our school got many positions
                            in various competitions.</p>

                        <div class="overflow-x-scroll">
                            <table class="table table-hover table-bordered">
                                <tbody>
                                    <tr>
                                        <th>Activity Name</th>
                                        <th>Name of the Students</th>
                                        <th>Position</th>
                                    </tr>
                                    <tr *ngFor="let activity of activitiesList">
                                        <td>{{ activity.activityName }}</td>
                                        <td>{{ activity.students }}</td>
                                        <td>{{ activity.position }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>