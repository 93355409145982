import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ShowcaseService } from 'src/app/services/showcase/showcase.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showHeader = true;
  showShowcase = true;
  sub: Subscription[] = [];
  isLoading: boolean[] = [];

  constructor(private showcaseService: ShowcaseService,) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.sub[1] = this.showcaseService.changedShowcase.subscribe(val => {
        this.showShowcase = val;
        if (this.showShowcase) {
          $('#showcase').attr('style', 'display:block');
        }
        if (!this.showShowcase) {
          $('#showcase').attr('style', 'display:none');
        }
      });
      this.sub[2] = this.showcaseService.changedHeader.subscribe(val => {
        if (val) {
          $('#header').attr('style', 'display:block');
        }
        if (!val) {
          $('#header').attr('style', 'display:none');
        }
      })
    });
    this.setLoader();
    $('.carousel').carousel({
      pause: false
    })
    this.setHeader();
    this.scrollHeader();
  }

  scrollHeader() {
    $(document).ready(function () {
      $(window).scroll(function () {
        //if you hard code, then use console
        //.log to determine when you want the 
        //nav bar to stick.  
        if ($(window).innerWidth() >= 568) {
          if ($(window).scrollTop() >= 110) {
            $('#menu-bar').attr('style', 'display:none');
            $('#menu-header1').attr('style', 'display:block');

          }
          if ($(window).scrollTop() == 0 || $(window).scrollTop() <= 109) {

            $('#menu-header1').attr('style', 'display:none');
            $('#menu-bar').attr('style', 'display:block');
          }
        }
        if ($(window).innerWidth() < 568) {
          $('#menu-header1').attr('style', 'display:none');
          $('#menu-bar').attr('style', 'display:block');
        }
      });
    });
  }

  setHeader() {
    const headers = [
      'group-name',
      'group-welcome',
      'group-welcome-2' // Add a unique ID for the third header
    ];
  
    let currentIndex = 0; // Keep track of the current visible header
  
    const source = interval(5000);
    this.sub[0] = source.subscribe(() => {
      // Hide all headers
      headers.forEach((id) => {
        document.getElementById(id).className = 'd-none animate__animated animate__slideInDown link-shadow';
      });
  
      // Show the current header
      document.getElementById(headers[currentIndex]).className = 'd-block animate__animated animate__slideInDown link-shadow';
  
      // Move to the next header, loop back to the first
      currentIndex = (currentIndex + 1) % headers.length;
    });
  }

  setLoader() {
    for (let i = 0; i < 5; i++) {
      this.isLoading.push(true);
    }
  }

  hideLoader(i: number) {
    this.isLoading[i] = false;
  }

  LinkUrl(id: string) {
    window.open(id);
  }

  ngOnDestroy() {
    this.sub.forEach(s => {
      s.unsubscribe();
    });
  }
}
