import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowcaseService {
  private showShowcase=true;
  private showHeader=true;
  changedShowcase=new BehaviorSubject<boolean>(true);
  changedHeader= new BehaviorSubject<boolean>(true);
  constructor() { }

  showingShowcase(){
    this.showShowcase=true;
    this.changedShowcase.next(this.showShowcase);
  }
  hideShowcase(){
    this.showShowcase=false;
    this.changedShowcase.next(this.showShowcase);
  }
  getShowcase(){
    return this.showShowcase;
  }

  showingHeader(){
    this.showHeader=true;
    this.changedHeader.next(true);
  }
  hideHeader(){
    this.showHeader=false;
    this.changedHeader.next(false);
  }
}
