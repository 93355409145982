<div id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg inner-card">
                    <h4 class="card-title text-center">Vision & Values</h4>
                    <div class="card-body ">
                        <p class="inner-lead">Our vision is to develop well rounded, confident and responsible
                            individuals who aspire to achieve their full potential. We will do this by providing a
                            welcoming, happy, safe and supportive learning environment in which everyone is equal and
                            all
                            achievements are celebrated. We are committed for providing a positive, safe and stimulating
                            environment for children to learn. Our focus is to provide such
                            environment which promotes each child's social, emotional, physical and
                            cognitive development.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>